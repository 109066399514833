import { useState, useEffect } from "react";
import {
  Table,
  message,
  Modal,
  Input,
  InputNumber,
  Button,
  Dropdown,
  Switch,
  Select,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  getVouchersList,
  deleteVoucher,
  updateVoucherStatus,
  addVoucher,
  updateVoucher,
} from "../../api/voucher.api";
import { Helmet } from "react-helmet";
import { getBooksList } from "../../api/book.api";
import moment from "moment";
import { formattedDate } from "../../components/common/FormattedDate";
import { Countdown } from "../../components/common/Countdown";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../components/common/FormatPrice";

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

const VoucherList = () => {
  const { t } = useTranslation("voucherList");
  const [vouchers, setVouchers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newVoucher, setNewVoucher] = useState({
    name: "",
    code: "",
    discount_value: "",
    discount_type: "percent",
    expiration_date: null,
    usage_limit: "",
    applicable_books: [],
  });
  const [editingVoucher, setEditingVoucher] = useState(null);

  const applicableBookIds = newVoucher.applicable_books.map((book) => book._id);

  const fetchVouchers = async () => {
    setLoading(true);
    try {
      const response = await getVouchersList();
      if (response?.success && Array.isArray(response.data)) {
        setVouchers(response.data.reverse());
      }
    } catch (error) {
      message.error("Lỗi khi tải dữ liệu voucher");
    } finally {
      setLoading(false);
    }
  };

  const handleEditVoucher = (voucher) => {
    setEditingVoucher(voucher);
    setNewVoucher({
      name: voucher.name,
      code: voucher.code,
      discount_value: voucher.discount_value,
      discount_type: voucher.discount_type,
      expiration_date: moment(voucher.expiration_date),
      usage_limit: voucher.usage_limit,
      applicable_books: voucher.applicable_books,
    });
    setIsModalVisible(true);
  };

  const handleSaveVoucher = async () => {
    try {
      const response = editingVoucher
        ? await updateVoucher(editingVoucher._id, {
          ...newVoucher,
          expiration_date: newVoucher.expiration_date?.format("YYYY-MM-DD"),
        })
        : await addVoucher({
          ...newVoucher,
          expiration_date: newVoucher.expiration_date?.format("YYYY-MM-DD"),
        });

      if (response.success) {
        message.success(
          editingVoucher ? "Sửa voucher thành công" : "Thêm voucher thành công"
        );
        fetchVouchers();
        setIsModalVisible(false);
        setNewVoucher({
          name: "",
          code: "",
          discount_value: "",
          discount_type: "percent",
          expiration_date: null,
          usage_limit: "",
          applicable_books: [],
        });
        setEditingVoucher(null);
      }
    } catch (error) {
      message.error("Lỗi kết nối hoặc lỗi từ API.");
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await getBooksList();
      if (response?.success && Array.isArray(response.data)) {
        setProducts(response.data);
      }
    } catch (error) {
      message.error("Lỗi khi tải danh sách sản phẩm");
    }
  };

  useEffect(() => {
    fetchVouchers();
    fetchProducts();
  }, []);

  const handleDelete = (voucherId) => {
    confirm({
      title: t("deleteConfirmTitle"),
      content: t("deleteConfirmContent"),
      okText: t("deleteConfirmOkText"),
      okType: "danger",
      cancelText: t("deleteConfirmCancelText"),
      onOk: async () => {
        try {
          const response = await deleteVoucher(voucherId);
          if (response.success) {
            message.success(t("deleteSuccessMessage"));
            fetchVouchers();
          }
        } catch {
          message.error(t("deleteErrorMessage"));
        }
      },
    });
  };

  const handleUpdateStatus = async (voucherId, isActive) => {
    const newStatus = isActive ? false : true;

    try {
      const response = await updateVoucherStatus(voucherId, {
        is_active: newStatus,
      });
      if (response.success) {
        message.success(t("statusUpdateSuccess"));
        fetchVouchers();
      }
    } catch {
      message.error(t("statusUpdateError"));
    }
  };

  const filteredVouchers = vouchers.filter((voucher) =>
    voucher.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    {
      title: t("columns.code"),
      dataIndex: "code",
      key: "code",
      align: "center",
    },
    {
      title: t("columns.name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("columns.discountValue"),
      dataIndex: "discount_value",
      key: "discount_value",
      align: "center",
      render: (value, record) => {
        if (record.discount_type === "fixed") {
          return formatCurrency(value);
        }
        
        return `${value}${record.discount_type === "percent" ? "%" : ""}`;
      }
    },
    {
      title: t("columns.discountType"),
      dataIndex: "discount_type",
      key: "discount_type",
      align: "center",
    },
    {
      title: t("columns.expirationDate"),
      dataIndex: "expiration_date",
      key: "expiration_date",
      align: "center",
      render: (text) => (
        <>
          {formattedDate(text)} <br />
          <Countdown expirationDate={text} />
        </>
      ),
    },
    {
      title: t("columns.usageLimit"),
      dataIndex: "usage_limit",
      key: "usage_limit",
      align: "center",
    },
    {
      title: t("columns.usedAt"),
      dataIndex: "used_at",
      key: "used_at",
      align: "center",
    },
    {
      title: t("columns.status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) => (
        <Switch
          checked={record.is_active}
          onChange={() => handleUpdateStatus(record._id, record.is_active)}
          checkedChildren={<i className="anticon anticon-check" />}
          unCheckedChildren={<i className="anticon anticon-close" />}
          style={{
            background: record.is_active ? "#00a99d" : "#d9d9d9",
            borderColor: record.is_active ? "#00a99d" : "#ff4d4f",
          }}
        />
      ),
    },
    {
      title: t("columns.actions"),
      key: "actions",
      align: "center",
      render: (_, record) => {
        const menuItems = [
          {
            key: "edit",
            label: t("actionLabels.edit"),
            icon: <EditOutlined />,
            onClick: () => handleEditVoucher(record),
          },
          {
            key: "delete",
            label: t("actionLabels.delete"),
            icon: <DeleteOutlined />,
            style: { color: 'red' },
            onClick: () => handleDelete(record._id),
          },
        ];
        
        return (
          <Dropdown menu={{ items: menuItems }}>
            <Button
              icon={<EllipsisOutlined />}
              onClick={(e) => e.preventDefault()}
            />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("pageTitle")}</title>
      </Helmet>

      <h1 style={{ paddingBottom: "10px" }}>{t("pageTitle")}</h1>

      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Search
          placeholder={t("searchPlaceholder")}
          allowClear
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: 300 }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
          style={{ backgroundColor: "#00a99d", borderColor: "#00a99d" }}
        >
          {t("addNewButton")}
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={filteredVouchers}
        rowKey="_id"
        loading={loading}
      />

      <Modal
        title={editingVoucher ? t("editModalTitle") : t("addModalTitle")}
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setNewVoucher({
            name: "",
            code: "",
            discount_value: "",
            discount_type: "percent",
            expiration_date: null,
            usage_limit: "",
            applicable_books: [],
          });
          setEditingVoucher(null);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsModalVisible(false);
              setNewVoucher({
                name: "",
                code: "",
                discount_value: "",
                discount_type: "percent",
                expiration_date: null,
                usage_limit: "",
                applicable_books: [],
              });
              setEditingVoucher(null);
            }}
          >
            {t("cancelButton")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleSaveVoucher}>
            {editingVoucher ? t("saveButton") : t("addButton")}
          </Button>,
        ]}
      >
        <Input
          placeholder={t("voucherNamePlaceholder")}
          value={newVoucher.name}
          onChange={(e) =>
            setNewVoucher({ ...newVoucher, name: e.target.value })
          }
          style={{ marginBottom: 16 }}
        />
        <Input
          placeholder={t("voucherCodePlaceholder")}
          value={newVoucher.code}
          onChange={(e) =>
            setNewVoucher({ ...newVoucher, code: e.target.value })
          }
          style={{ marginBottom: 16 }}
        />
        <InputNumber
          placeholder={t("discountValuePlaceholder")}
          value={newVoucher.discount_value}
          onChange={(value) => {
            setNewVoucher({
              ...newVoucher,
              discount_value: value !== undefined && value !== null ? value : 0,
            });
          }}
          style={{ marginBottom: 16, width: "100%" }}
          formatter={(value) =>
            value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : ""
          }
          parser={(value) =>
            value ? value.replace(/\./g, "") : ""
          }
        />
        <Select
          value={newVoucher.discount_type}
          onChange={(value) =>
            setNewVoucher({ ...newVoucher, discount_type: value })
          }
          style={{ width: "100%", marginBottom: 16 }}
        >
          <Option value="percent">{t("discountTypes.percent")}</Option>
          <Option value="fixed">{t("discountTypes.fixed")}</Option>
        </Select>
        <DatePicker
          placeholder={t("expirationDatePlaceholder")}
          value={newVoucher.expiration_date}
          onChange={(date) =>
            setNewVoucher({ ...newVoucher, expiration_date: date })
          }
          style={{ width: "100%", marginBottom: 16 }}
        />
        <Input
          placeholder={t("usageLimitPlaceholder")}
          type="number"
          value={newVoucher.usage_limit}
          onChange={(e) =>
            setNewVoucher({ ...newVoucher, usage_limit: e.target.value })
          }
          style={{ marginBottom: 16 }}
        />

        <Select
          mode="multiple"
          showSearch
          placeholder={t("applicableBooksPlaceholder")}
          value={applicableBookIds}
          onChange={(value) => {
            const selectedBooks = value.map((id) =>
              products.find((product) => product._id === id)
            );
            setNewVoucher({ ...newVoucher, applicable_books: selectedBooks });
          }}
          style={{ width: "100%", marginBottom: 16 }}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {products.map((product) => (
            <Option key={product._id} value={product._id}>
              {product.title}
            </Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default VoucherList;
