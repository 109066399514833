import { get, post, put, del } from '../utils/request';

export const getShippingFeesList = async () => {
  const result = await get('shipping-fees');
  return result;
};

export const addShippingFee = async (shippingFeeData) => {
  const result = await post('shipping-fees', shippingFeeData);
  return result;
};

export const updateShippingFee = async (id, shippingFeeData) => {
  const result = await put(`shipping-fees/${id}`, shippingFeeData);
  return result;
};

export const deleteShippingFee = async (id) => {
  const result = await del(`shipping-fees/${id}`);
  return result;
};