import { useState, useEffect } from "react";
import { Table, Button, Row, Col, Input, Modal, Form, message } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getAccountAdmin, deleteAdmin, registerAdmin } from "../../api/admin.api";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const AdminManager = () => {
  const { t } = useTranslation("adminManager"); 
  const [adminData, setAdminData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteAdminId, setDeleteAdminId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    {
      title: t("user_name"),
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
    },
    {
      title: t("full_name"),
      dataIndex: "full_name",
      key: "full_name",
      align: "center",
    },
    {
      title: t("action"),
      key: "action",
      align: "center", 
      render: (_, record) => (
        <Button
          danger
          onClick={() => showDeleteModal(record._id)}
          style={{ width: "70px" }}
        >
          {t("Delete")}
        </Button>
      ),
    },
  ];
  

  const loadAdminData = async () => {
    try {
      const response = await getAccountAdmin();
      setAdminData(response.data);
    } catch (error) {
      message.error(t("delete_error"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAdminData();
  }, []);

  const showDeleteModal = (adminId) => {
    setDeleteAdminId(adminId);
    setIsDeleteModalVisible(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteAdmin(deleteAdminId);
      if (response.success) {
        setAdminData(adminData.filter((admin) => admin._id !== deleteAdminId));
        message.success(t("delete_success"));
      }
    } catch (error) {
      message.error(t("delete_error"));
    }
    setIsDeleteModalVisible(false);
  };

  const cancelDelete = () => {
    setIsDeleteModalVisible(false);
  };

  const handleAddAdmin = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const adminPayload = {
        user_name: values.user_name,
        full_name: values.full_name,
        password: values.password,
        isAdmin: true,
      };
  
      const result = await registerAdmin(adminPayload);
  
      const newAdmin = {
        _id: result.data._id, 
        user_name: result.data.user_name,
        full_name: result.data.full_name,
      };
  
      setAdminData((prevData) => [...prevData, newAdmin]);
      message.success(t("add_success"));
      form.resetFields();
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error in handleOk:", error);
      message.error(t("add_error"));
    }
  };
  

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const filteredAdminData = adminData.filter(
    (admin) =>
      admin.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      admin.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>{t("admin_management")}</title>
      </Helmet>

      <div className="admin-manager-container">
        <h1 style={{ paddingBottom: "20px" }}>{t("admin_list")}</h1>

        <Row gutter={16} justify="space-between" style={{ marginBottom: 16 }}>
          <Col span={6}>
            <Input.Search
              placeholder={t("search_placeholder")}
              onSearch={(value) => setSearchQuery(value)}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddAdmin}
              style={{ backgroundColor: "#00a99d" }}
            >
              {t("add_admin")}
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={filteredAdminData}
          rowKey="_id"
          loading={loading}
          pagination={{ pageSize: 5 }}
        />

        <Modal
          title={t("add_admin_title")}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          cancelText={t("cancel")}
          okText={t("add")}
          width={400}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="full_name"
              label={t("full_name")}
              rules={[{ required: true, message: t("add_error") }]}
            >
              <Input placeholder={t("full_name")} />
            </Form.Item>
            <Form.Item
              name="user_name"
              label={t("user_name")}
              rules={[{ required: true, message: t("add_error") }]}
            >
              <Input placeholder={t("user_name")} />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("password")}
              rules={[{ required: true, message: t("add_error") }]}
            >
              <Input.Password placeholder={t("password")} />
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title={t("delete_confirm_title")}
          open={isDeleteModalVisible}
          onOk={confirmDelete}
          onCancel={cancelDelete}
          okText={t("confirm")}
          cancelText={t("no")}
          okType="danger"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <ExclamationCircleOutlined
              style={{ fontSize: "24px", color: "red", marginRight: "10px" }}
            />
            <p>{t("delete_confirm_message")}</p>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AdminManager;
