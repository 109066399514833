import React, { useEffect, useState } from "react";
import {
  Descriptions,
  Rate,
  Table,
  Button,
  Typography,
  message,
  Switch,
  Image,
  Select,
  Popconfirm,
  Spin,
} from "antd";
import {
  fetchReviews,
  toggleReviewVisibility,
  deleteReview,
} from "../../../api/review.api";
import { getBooksByIdOrSlug } from "../../../api/book.api";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { formattedDate } from "../../../components/common/FormattedDate";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { Option } = Select;

const BookDetail = () => {
  const { t } = useTranslation("bookDetail");
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [bookDetails, setBookDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingReviewIds, setLoadingReviewIds] = useState([]);
  const { id: bookId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const fetchedReviews = await fetchReviews(bookId);
        setReviews(fetchedReviews);
        setFilteredReviews(fetchedReviews);

        const response = await getBooksByIdOrSlug(bookId);
        setBookDetails(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error loading data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [bookId]);

  const handleFilterChange = (value) => {
    if (value === "all") {
      setFilteredReviews(reviews);
    } else {
      const filtered = reviews.filter(
        (review) => review.rating === Number(value)
      );
      setFilteredReviews(filtered);
    }
  };

  const handleDeleteReview = async (reviewId) => {
    try {
      await deleteReview(reviewId);
      setReviews((prevReviews) =>
        prevReviews.filter((review) => review._id !== reviewId)
      );
      setFilteredReviews((prevReviews) =>
        prevReviews.filter((review) => review._id !== reviewId)
      );
      message.success("Đã xóa đánh giá thành công");
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    }
  };

  const handleToggleVisibility = async (reviewId, currentVisibility) => {
    setLoadingReviewIds((prev) => [...prev, reviewId]);
    setReviews((prevReviews) =>
      prevReviews.map((review) =>
        review._id === reviewId
          ? { ...review, is_hidden: !currentVisibility }
          : review
      )
    );
    setFilteredReviews((prevReviews) =>
      prevReviews.map((review) =>
        review._id === reviewId
          ? { ...review, is_hidden: !currentVisibility }
          : review
      )
    );

    try {
      const updatedReview = await toggleReviewVisibility(
        reviewId,
        currentVisibility
      );

      if (updatedReview && typeof updatedReview.is_hidden === "boolean") {
        setReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === reviewId
              ? { ...review, is_hidden: updatedReview.is_hidden }
              : review
          )
        );
        setFilteredReviews((prevReviews) =>
          prevReviews.map((review) =>
            review._id === reviewId
              ? { ...review, is_hidden: updatedReview.is_hidden }
              : review
          )
        );
        message.success("Cập nhật trạng thái đánh giá thành công !");
      }
    } catch (error) {
      setReviews((prevReviews) =>
        prevReviews.map((review) =>
          review._id === reviewId
            ? { ...review, is_hidden: currentVisibility }
            : review
        )
      );
      setFilteredReviews((prevReviews) =>
        prevReviews.map((review) =>
          review._id === reviewId
            ? { ...review, is_hidden: currentVisibility }
            : review
        )
      );
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoadingReviewIds((prev) => prev.filter((id) => id !== reviewId));
    }
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const columns = [
    {
      title: t("reviewer"),
      dataIndex: "user",
      key: "user",
      align: "center",
      render: (user) => <div>{user.full_name}</div>,
    },
    {
      title: t("time"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt) => new Date(createdAt).toLocaleString(),
    },
    {
      title: t("rating"),
      dataIndex: "rating",
      key: "rating",
      align: "center",
      render: (rating) => <Rate disabled defaultValue={rating} />,
    },
    {
      title: t("review_content"),
      dataIndex: "content",
      key: "content",
      align: "center",
    },
    {
      title: t("hide_review"),
      dataIndex: "is_hidden",
      key: "is_hidden",
      align: "center",
      render: (is_hidden, record) => (
        <Switch
          checked={is_hidden}
          onChange={() => handleToggleVisibility(record._id, is_hidden)}
          loading={loadingReviewIds.includes(record._id)}
        />
      ),
    },
    {
      title: t("actions"),
      key: "action",
      align: "center",
      render: (_, record) => (
        <Popconfirm
          title={t("confirm_delete_review")}
          onConfirm={() => handleDeleteReview(record._id)}
          okText={t("delete")}
          cancelText={t("cancel")}
          okButtonProps={{ danger: true }}
        >
          <Button
            type="text"
            danger
            style={{ border: "1px solid red", color: "red" }}
          >
            {t("delete")}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{bookDetails?.title}</title>
      </Helmet>

      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Title level={4}>
            {t("book_details")} "{bookDetails?.title || "Loading..."}"
          </Title>

          <div
            style={{
              marginTop: "20px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <div style={{ marginLeft: "10px" }}>
              <Text type="secondary">{bookDetails?.category?.name}</Text>
              <br />
              <Title level={5}>{bookDetails?.title || "Loading..."}</Title>
            </div>
            <div
              style={{
                width: "100%",
                height: "120px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Image.PreviewGroup>
                {bookDetails?.images.map((src, index) => (
                  <Image
                    key={index}
                    src={src}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "120px",
                      height: "120px",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            </div>
          </div>

          <div
            style={{
              marginTop: "20px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Descriptions
              title={t("Thông tin chung")}
              column={2}
              bordered={false}
            >
              <Descriptions.Item label={t("book_title")}>
                {bookDetails?.title}
              </Descriptions.Item>
              <Descriptions.Item label={t("author")}>
                {bookDetails?.author?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("category")}>
                {bookDetails?.category?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("publisher")}>
                {bookDetails?.publisher?.name}
              </Descriptions.Item>
              <Descriptions.Item label={t("publish_date")}>
                {formattedDate(bookDetails?.detail?.published_at)}
              </Descriptions.Item>
              <Descriptions.Item label={t("dimensions")}>
                {bookDetails?.detail?.dimensions?.width} x{" "}
                {bookDetails?.detail?.dimensions?.height} x{" "}
                {bookDetails?.detail?.dimensions?.length} cm
              </Descriptions.Item>
              <Descriptions.Item label={t("pages")}>
                {bookDetails?.detail?.pages} {t("pages_label")}
              </Descriptions.Item>
              <Descriptions.Item label={t("weight")}>
                {bookDetails?.detail?.weight} {t("grams")}
              </Descriptions.Item>
              <Descriptions.Item label={t("description")} span={2}>
                {stripHtml(bookDetails?.description)}
              </Descriptions.Item>
            </Descriptions>
          </div>

          <div style={{ marginTop: "20px" }}>
            <Text strong>{t("filter_by_rating")}: </Text>
            <Select
              defaultValue="all"
              onChange={handleFilterChange}
              style={{ width: 120, marginLeft: "10px" }}
            >
              <Option value="all">{t("all")}</Option>
              {[5, 4, 3, 2, 1].map((value) => (
                <Option key={value} value={value}>
                  {value} {t("star")}
                </Option>
              ))}
            </Select>
          </div>

          <div
            style={{
              marginTop: "10px",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <Table
              dataSource={Array.isArray(filteredReviews) ? filteredReviews : []}
              columns={columns}
              pagination={false}
              rowKey="_id"
            />
          </div>
        </>
      )}
    </>
  );
};

export default BookDetail;
