import { Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export const Countdown = ({ expirationDate }) => {
    const [countdown, setCountdown] = useState(null);
  const { t } = useTranslation("voucherList");
    useEffect(() => {
        const expiration = new Date(expirationDate);

        const updateCountdown = () => {
            const now = new Date();
            const timeDiff = expiration - now;

            if (timeDiff <= 0) {
                setCountdown(t("Đã hết hạn"));
            } else {
                const hours = Math.floor(timeDiff / (1000 * 60 * 60));
                const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
               const countdownText = t("format", {
      hours,
      minutes,
      seconds,
    });
    setCountdown(countdownText);
            }
        };

        const intervalId = setInterval(updateCountdown, 1000);
        updateCountdown();

        return () => clearInterval(intervalId);
    }, [expirationDate]);

    return countdown ? (
       <Tag 
    color="blue" 
    style={{ color: countdown === "Đã hết hạn" || countdown === "Expired" ? "red" : "inherit" }}
>
    {countdown}
</Tag>

    ) : null;
};