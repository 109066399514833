import { useState } from "react";
import { Modal, Input, Form, Button, message, Spin } from "antd";
import { changePassword } from "../../../api/admin.api";
import { useTranslation } from "react-i18next";

const ChangePasswordModal = ({ onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleChangePassword = async (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error(t("changePassword.passwordMismatch"));
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await changePassword({ oldPassword, newPassword }, token);

      message.success(response.message || t("changePassword.success"));
      onClose();  
      form.resetFields(); 
    } catch (error) {
      message.error(t("changePassword.fail"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t("changePassword.modalTitle")}
      open={true}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        form={form}
        onFinish={handleChangePassword}
        layout="vertical"
        initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
      >
        <Form.Item
          name="oldPassword"
          label={t("changePassword.oldPassword")}
          rules={[{ required: true, message: t("changePassword.oldPasswordRequired") }]}
        >
          <Input.Password placeholder={t("changePassword.oldPasswordPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label={t("changePassword.newPassword")}
          rules={[{ required: true, message: t("changePassword.newPasswordRequired") }]}
        >
          <Input.Password placeholder={t("changePassword.newPasswordPlaceholder")} />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label={t("changePassword.confirmPassword")}
          rules={[{ required: true, message: t("changePassword.confirmPasswordRequired") }]}
        >
          <Input.Password placeholder={t("changePassword.confirmPasswordPlaceholder")} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            loading={loading}
          >
            {loading ? <Spin /> : t("changePassword.submitButton")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
