import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAdmin } from "../../contexts/getAdminInfo";
import Loading from "../common/Loading";

function PrivateRoutes() {
  const { adminInfo, isLoading } = useAdmin();
  const [isDelayedLoading, setIsDelayedLoading] = useState(true);

  useEffect(() => {
    let timer;
    if (!isLoading) {
      timer = setTimeout(() => setIsDelayedLoading(false), 1000); 
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  if (isLoading || isDelayedLoading) {
    return (
      <div className="loading-container">
        <Loading />
      </div>
    );
  }

  return adminInfo ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoutes;
