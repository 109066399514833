import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Dropdown } from "antd";
import {
  BellOutlined,
  InboxOutlined,
  CloseCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import Pusher from "pusher-js";
import "./Notify.scss";
import { getNewOrders, resetOrdersCount, getPostsPending, resetPostsPending } from "../../api/notification.api";
import { timeAgo } from "../common/TimeAgo";
import { useTranslation } from "react-i18next";

function Notify() {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const storedNotifications = JSON.parse(localStorage.getItem("notifications")) || [];
    const storedCount = parseInt(localStorage.getItem("notificationCount"), 10) || 0;

    setNotifications(storedNotifications);
    setCount(storedCount);
  }, []);

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      encrypted: true,
    });

    const channel = pusher.subscribe("admin-notifications");

    const handleNewNotification = (data) => {
      setNotifications((prev) => {
        const updatedNotifications = [data, ...prev];
        localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
        return updatedNotifications;
      });

      setCount((prev) => {
        const newCount = prev + 1;
        localStorage.setItem("notificationCount", newCount);
        return newCount;
      });
    };

    channel.bind("new-order", handleNewNotification);
    channel.bind("cancel-order", handleNewNotification);
    channel.bind("new-post-approval", handleNewNotification);

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const orderResponse = await getNewOrders();
        const orderData = orderResponse?.data || [];

        const postResponse = await getPostsPending();
        const postData = postResponse?.data || [];

        const allNotifications = [
          ...(orderData.notifications || []),
          ...(postData.notifications || []),
        ];

        setNotifications(allNotifications);
        setCount(allNotifications.length);
        localStorage.setItem("notifications", JSON.stringify(allNotifications));
        localStorage.setItem("notificationCount", allNotifications.length);
      } catch (error) {
        console.error(t("notification.error"), error);
      }
    };

    const storedNotifications = JSON.parse(localStorage.getItem("notifications"));
    if (!storedNotifications || storedNotifications.length === 0) {
      fetchNotifications();
    }
  }, [t]);

  const handleDropdown = async (visible) => {
    if (visible) {
      try {
        await resetOrdersCount();
        await resetPostsPending();
        setCount(0); 
        localStorage.setItem("notificationCount", 0);
      } catch (error) {
        console.error(t("notification.resetError"), error);
      }
    }
  };

  const items = notifications.map((notify, index) => ({
    key: index,
    label: (
      <Link
        to={
          notify.type === "new-post-approval"
            ? `/post-detail/${notify.postId}`
            : `/order-detail/${notify.code}`
        }
        className="notify__item"
        key={index}
      >
        {notify.type === "new-post-approval" ? (
          <div className="notify__item-icon-1">
            <FileTextOutlined />
          </div>
        ) : notify.status === "cancelled" ? (
          <div className="notify__item-icon-2">
            <CloseCircleOutlined />
          </div>
        ) : (
          <div className="notify__item-icon-3">
            <InboxOutlined />
          </div>
        )}
        <div className="notify__item-content">
          <div className="notify__item-title">
            {notify.type === "new-post-approval"
              ? t("notification.newPostApproval")
              : notify.status === "cancelled"
              ? t("notification.cancelledOrder")  
              : t("notification.newOrder")}
          </div>
          <div className="notify__item-subtitle">
            {notify.type === "new-post-approval"
              ? `${notify.content}`
              : `${t("notification.orderCode")}: ${notify.code}`}
          </div>
          <div className="notify__item-time">{timeAgo(notify.createdAt, t)}</div>
        </div>
      </Link>
    ),
  }));

  return (
    <>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        onOpenChange={handleDropdown}
        dropdownRender={(menu) => (
          <div className="notify__dropdown">
            <div className="notify__header">
              <div className="notify__header-title">
                <BellOutlined /> {t("notification.newNotifications")}
              </div>
            </div>
            <div className="notify__body">
              {items && items.length > 0 ? (
                menu
              ) : (
                <p className="notify__not">{t("notification.noNotifications")}</p>
              )}
            </div>
          </div>
        )}
      >
        <Badge count={count} style={{ zIndex: "99999999" }}>
          <Button type="text" icon={<BellOutlined style={{ fontSize: "18px" }} />} />
        </Badge>
      </Dropdown>
    </>
  );
}

export default Notify;
