import { useEffect, useState } from "react";
import {
  Card,
  Button,
  Typography,
  Row,
  Col,
  Table,
  Input,
  Modal,
  Spin,
} from "antd";
import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import { AiOutlineEye } from "react-icons/ai";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
import {
  getTransactions,
  getTransactionByRef,
} from "../../api/transaction.api";
import { formatCurrency } from "../../components/common/FormatPrice";
import "./TransactionManager.scss";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Title, Text } = Typography;

const TransactionManager = () => {
  const { t } = useTranslation("transactionManager");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const atText = t("at");
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await getTransactions();
        setData(result.reverse());
        setFilteredData(result);
      } catch (error) {
        console.error("Không thể tải dữ liệu giao dịch:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);

    const filtered = data.filter(
      (item) =>
        item.txnRef?.toLowerCase().includes(value) ||
        item.bankCode?.toLowerCase().includes(value) ||
        item.transactionNo?.toLowerCase().includes(value) ||
        moment(item.createdAt).format("DD/MM/YYYY").includes(value)
    );

    setFilteredData(filtered);
  };

  const handleDetailClick = async (txnRef) => {
    setIsModalVisible(true);
    setModalLoading(true);
    try {
      const result = await getTransactionByRef(txnRef);
      setSelectedTransaction(result);
    } catch (error) {
      console.error("Không thể tải chi tiết giao dịch:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedTransaction(null);
  };

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sao kê giao dịch");

    worksheet.columns = [
      { header: "Mã giao dịch", key: "txnRef", width: 15 },
      { header: "Ngày tạo", key: "createdAt", width: 25 },
      { header: "Số tiền (VNĐ)", key: "amount", width: 20 },
      { header: "Nội dung chuyển tiền", key: "orderInfo", width: 40 },
      { header: "Mã ngân hàng", key: "bankCode", width: 15 },
      { header: "Số giao dịch", key: "transactionNo", width: 20 },
    ];

    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell, colNumber) => {
      cell.font = { bold: true, color: { argb: "000000" }, size: 13 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "57fff4" },
      };
      cell.alignment = { horizontal: "center", vertical: "middle" };
    });

    const dataToExport = filteredData.map((item) => ({
      txnRef: item.txnRef,
      createdAt: moment(item.createdAt).format(`DD/MM/YYYY [${t("at")}] HH:mm`),
      amount: formatCurrency(item.amount),
      orderInfo: item.orderInfo,
      bankCode: item.bankCode,
      transactionNo: item.transactionNo,
    }));

    dataToExport.forEach((data) => {
      worksheet.addRow(data);
    });

    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      if (rowNumber > 1) {
        row.alignment = { horizontal: "left", vertical: "middle" };
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, "Sao_ke_giao_dich.xlsx");
  };

  const columns = [
    {
      title: t("txnRef"),
      dataIndex: "txnRef",
      key: "txnRef",
      align: "center",
    },
    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => formatCurrency(amount),
      align: "center",
    },
    {
      title: t("bankCode"),
      dataIndex: "bankCode",
      key: "bankCode",
      align: "center",
    },
    {
      title: t("transactionNo"),
      dataIndex: "transactionNo",
      key: "transactionNo",
      align: "center",
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => moment(date).format(`DD/MM/YYYY [${atText}] HH:mm`),
      align: "center",
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{
              borderColor: "#00a99d",
              color: "#00a99d",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => handleDetailClick(record.txnRef)}
          >
            <AiOutlineEye style={{ marginRight: 5 }} /> {t("detailButton")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <Card className="payment-transactions-card">
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={3}>{t("title")}</Title>
          </Col>
          <Col>
            <Button
              style={{
                borderColor: "#1c7430",
                color: "#1c7430",
                fontWeight: "600",
              }}
              icon={<FileExcelOutlined />}
              onClick={exportToExcel}
            >
              {t("exportButton")}
            </Button>
          </Col>
        </Row>

        <Input
          placeholder={t("searchPlaceholder")}
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={handleSearch}
          allowClear
          style={{ marginTop: 20, width: 300 }}
        />

        <Table
          columns={columns}
          dataSource={filteredData}
          pagination={{ pageSize: 8 }}
          bordered
          rowKey={(record) => record._id}
          style={{ marginTop: "20px" }}
        />

        <Modal
          title={t("modalTitle")}
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width={800}
          className="payment-detail-modal"
        >
          ...
          {modalLoading ? (
            <Spin size="large" style={{ display: "block", margin: "auto" }} />
          ) : selectedTransaction ? (
            <div>
              <p>
                <Text strong>{t("txnRef")}:</Text> {selectedTransaction.txnRef}
              </p>
              <p>
                <Text strong>{t("amount")}:</Text>{" "}
                {formatCurrency(selectedTransaction.amount)} VNĐ
              </p>
              <p>
                <Text strong>{t("bankCode")}:</Text>{" "}
                {selectedTransaction.bankCode}
              </p>
              <p>
                <Text strong>{t("transactionNo")}:</Text>{" "}
                {selectedTransaction.transactionNo}
              </p>
              <p>
                <Text strong>{t("orderInfo")}:</Text>{" "}
                {selectedTransaction.orderInfo}
              </p>
              <p>
                <Text strong>{t("createdAt")}:</Text>{" "}
                {moment(selectedTransaction.createdAt).format(
                  `DD/MM/YYYY [${t("at")}] HH:mm`
                )}
              </p>
            </div>
          ) : (
            <Text type="danger">{t("noTransactionDetail")}</Text>
          )}
        </Modal>
      </Card>
    </>
  );
};

export default TransactionManager;
