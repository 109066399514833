import { createContext, useState, useContext, useEffect } from "react";
import { getAdminInfo } from "../api/admin.api";

const AdminContext = createContext(null);

export const AdminProvider = ({ children }) => {
  const [adminInfo, setAdminInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeAdminInfo = async () => {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          const fetchedAdminInfo = await getAdminInfo(token);
          if (fetchedAdminInfo.data) {
            setAdminInfo(fetchedAdminInfo.data);
            localStorage.setItem("adminInfo", JSON.stringify(fetchedAdminInfo.data));
          }
        }
      } catch (error) {
        console.error("Error fetching admin info:", error);
        localStorage.removeItem("token");
        setAdminInfo(null);
      } finally {
        setIsLoading(false);
      }
    };
    initializeAdminInfo();
  }, []);

  const clearAdminInfo = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("adminInfo");
    setAdminInfo(null);
  };

  return (
    <AdminContext.Provider value={{ adminInfo, setAdminInfo, clearAdminInfo, isLoading }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
