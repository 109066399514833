import { useEffect, useState, useRef } from "react";
import {
  Collapse,
  Input,
  Button,
  Row,
  Col,
  Dropdown,
  Menu,
  Modal,
  Spin,
  message,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  SettingOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  getCategoriesList,
  addCategory,
  editCategory,
  deleteCategory,
} from "../../api/category.api";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Panel } = Collapse;
const { confirm } = Modal;

const CategoriesList = () => {
  const { t } = useTranslation("categoryManager");
  const [categories, setCategories] = useState([]);
  const [newCategoryForm, setNewCategoryForm] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [parentToEdit, setParentToEdit] = useState(null);
  const [isEditParentModalVisible, setIsEditParentModalVisible] =
    useState(false);
  const [subcategoryForm, setSubcategoryForm] = useState({
    name: "",
    description: "",
  });
  const [parentForSubcategory, setParentForSubcategory] = useState(null);
  const [isSubcategoryModalVisible, setIsSubcategoryModalVisible] =
    useState(false);
  const [isEditSubcategoryModalVisible, setIsEditSubcategoryModalVisible] =
    useState(false);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);
  const [subcategories, setSubcategories] = useState([]);

  // Refs for input fields in the modals
  const subcategoryNameInputRef = useRef(null);
  const subcategoryDescriptionInputRef = useRef(null);

  // Error state for forms
  const [newCategoryFormErrors, setNewCategoryFormErrors] = useState({
    name: "",
    description: "",
  });
  const [subcategoryFormErrors, setSubcategoryFormErrors] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const result = await getCategoriesList();
      setCategories(Array.isArray(result.data) ? result.data : []);
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleNewCategoryInputChange = (e) => {
    const { name, value } = e.target;
    setNewCategoryForm({ ...newCategoryForm, [name]: value });

    // Validate name on change
    if (name === "name") {
      if (!value.trim()) {
        setNewCategoryFormErrors({
          ...newCategoryFormErrors,
          name: t("category_name_required"),
        });
      } else {
        setNewCategoryFormErrors({ ...newCategoryFormErrors, name: "" });
      }
    }
  };

  const handleAddCategory = async () => {
    if (!newCategoryForm.name.trim()) {
      setNewCategoryFormErrors({
        ...newCategoryFormErrors,
        name: t("category_name_required"),
      });
      return;
    }

    setLoading(true);
    try {
      await addCategory(newCategoryForm);
      fetchCategories();
      setNewCategoryForm({ name: "", description: "", parent: null });
      message.success("Đã thêm danh mục thành công");
    } catch (error) {
      message.error("Vui lòng điền đầy đủ thông tin");
    } finally {
      setLoading(false);
    }
  };

  const openEditParentModal = (parentCategory) => {
    setParentToEdit(parentCategory);
    setNewCategoryForm({
      name: parentCategory.name,
      description: parentCategory.description,
    });
    setIsEditParentModalVisible(true);
  };

  const handleEditParentCategory = async () => {
    if (!parentToEdit) return;
    if (!newCategoryForm.name.trim()) {
      setNewCategoryFormErrors({
        ...newCategoryFormErrors,
        name: "Tên danh mục không được để trống",
      });
      return;
    }

    setLoading(true);
    try {
      await editCategory(parentToEdit._id, newCategoryForm);
      fetchCategories();
      setIsEditParentModalVisible(false);
      message.success(t("edit_subcategory_success"));
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteParent = async (parentId) => {
    confirm({
      title: t("delete_confirmation_content"),
      content: t("Hành động này không thể hoàn tác."),
      okText: t("delete_confirmation_ok"),
      okType: "danger",
      cancelText: t("delete_confirmation_cancel"),
      onOk: async () => {
        setLoading(true);
        try {
          await deleteCategory(parentId);
          fetchCategories();
          message.success(t("delete_category_success"));
        } catch (error) {
          const errorMessage = error.response?.data?.error;
          message.error(errorMessage);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const openSubcategoryModal = (parentCategory) => {
    setParentForSubcategory(parentCategory);
    setSubcategoryForm({ name: "", description: "" });
    setSubcategories(parentCategory.children || []);
    setIsSubcategoryModalVisible(true);
  };

  const handleSubcategoryFormChange = (e) => {
    const { name, value } = e.target;
    setSubcategoryForm({ ...subcategoryForm, [name]: value });

    // Validate name on change
    if (name === "name") {
      if (!value.trim()) {
        setSubcategoryFormErrors({
          ...subcategoryFormErrors,
          name: "Tên danh mục phụ không được để trống",
        });
      } else {
        setSubcategoryFormErrors({ ...subcategoryFormErrors, name: "" });
      }
    }
  };

  const handleAddSubcategory = async () => {
    if (!parentForSubcategory) return;

    if (!subcategoryForm.name.trim()) {
      setSubcategoryFormErrors({
        ...subcategoryFormErrors,
        name: "Tên danh mục phụ không được để trống",
      });
      return;
    }

    setLoading(true);
    try {
      const newSubcategory = {
        ...subcategoryForm,
        parent: parentForSubcategory._id,
      };

      const addedSubcategory = await addCategory(newSubcategory);

      setCategories((prevCategories) =>
        prevCategories.map((category) =>
          category._id === parentForSubcategory._id
            ? {
              ...category,
              children: [...category.children, addedSubcategory.data],
            }
            : category
        )
      );

      setSubcategoryForm({ name: "", description: "" });
      setIsSubcategoryModalVisible(false);
      message.success(t("add_subcategory_success"));
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSubcategory = async (subcategoryId) => {
    confirm({
      title: t("delete_confirmation_content"),
      content: t("Hành động này không thể hoàn tác."),
      okText: t("delete_confirmation_ok"),
      okType: "danger",
      cancelText: t("delete_confirmation_cancel"),
      onOk: async () => {
        setLoading(true);
        try {
          await deleteCategory(subcategoryId);
          setSubcategories(
            subcategories.filter((sub) => sub._id !== subcategoryId)
          );
          fetchCategories();
          message.success("Đã xóa danh mục phụ thành công");

          // Clear validation classes after successful deletion:
          if (subcategoryNameInputRef.current) {
            subcategoryNameInputRef.current.input.classList.remove(
              "ant-input-status-error"
            );
            const wrapper = subcategoryNameInputRef.current.input.closest(
              ".ant-input-wrapper"
            );
            if (wrapper) {
              wrapper.classList.remove("ant-input-wrapper-status-error");
            }
          }
          if (subcategoryDescriptionInputRef.current) {
            subcategoryDescriptionInputRef.current.resizableTextArea.textArea.classList.remove(
              "ant-input-status-error"
            );
            const wrapperTextArea =
              subcategoryDescriptionInputRef.current.resizableTextArea.textArea.closest(
                ".ant-input-textarea-show-count"
              );
            if (wrapperTextArea) {
              wrapperTextArea.classList.remove(
                "ant-input-textarea-show-count-status-error"
              );
            }
          }
        } catch (error) {
          const errorMessage = error.response?.data?.error;
          message.error(errorMessage);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const openEditSubcategoryModal = (subcategory) => {
    setSubcategoryToEdit(subcategory);
    setSubcategoryForm({
      name: subcategory.name,
      description: subcategory.description,
    });
    setIsEditSubcategoryModalVisible(true);
  };

  const handleEditSubcategory = async () => {
    if (!subcategoryToEdit) return;
    if (!subcategoryForm.name.trim()) {
      setSubcategoryFormErrors({
        ...subcategoryFormErrors,
        name: "Tên danh mục phụ không được để trống",
      });
      return;
    }

    setLoading(true);
    try {
      const updatedData = {
        name: subcategoryForm.name,
        description: subcategoryForm.description,
        parent: subcategoryToEdit.parent._id,
      };

      await editCategory(subcategoryToEdit._id, updatedData);

      setCategories((prevCategories) =>
        prevCategories.map((parentCategory) =>
          parentCategory._id === subcategoryToEdit.parent._id
            ? {
              ...parentCategory,
              children: parentCategory.children.map((child) =>
                child._id === subcategoryToEdit._id
                  ? {
                    ...child,
                    name: subcategoryForm.name,
                    description: subcategoryForm.description,
                  }
                  : child
              ),
            }
            : parentCategory
        )
      );

      setIsEditSubcategoryModalVisible(false);
      setSubcategoryToEdit(null);
      setSubcategoryForm({ name: "", description: "" });
      message.success(t("edit_subcategory_success"));
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const renderCategoryItems = (categories) =>
    categories.map((category) => {
      const parentMenuItems = [
        {
          key: "add-subcategory",
          label: (
            <>
              <PlusCircleOutlined />
              <span style={{ paddingLeft: "10px" }}>{t("add_subcategory")}</span>
            </>
          ),
          onClick: () => openSubcategoryModal(category),
        },
        {
          key: "edit-parent",
          label: (
            <>
              <EditOutlined />
              <span style={{ paddingLeft: "10px" }}>{t("edit_category")}</span>
            </>
          ),
          onClick: () => openEditParentModal(category),
        },
        {
          key: "delete-parent",
          label: (
            <>
              <DeleteOutlined />
              <span style={{ paddingLeft: "10px" }}>{t("delete_category")}</span>
            </>
          ),
          danger: true,
          onClick: () => confirmDeleteParent(category._id),
        },
      ];

      return {
        key: category._id,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span>{category.name}</span>
            <Dropdown menu={{ items: parentMenuItems }} trigger={["click"]}>
              <SettingOutlined
                style={{ fontSize: "16px", cursor: "pointer" }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </div>
        ),
        children: category.children && category.children.length > 0 && (
          <ul
            style={{
              paddingLeft: "15px",
              listStyleType: "none",
              margin: "5px 0",
              padding: 0,
            }}
          >
            {category.children.map((child, index) => (
              <li
                key={child._id}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 0",
                  borderBottom:
                    index !== category.children.length - 1
                      ? "1px solid #f0f0f0"
                      : "none",
                }}
              >
                <span>{child.name}</span>
                <div>
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => openEditSubcategoryModal(child)}
                    style={{ marginRight: "8px" }}
                  />
                  <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteSubcategory(child._id)}
                    danger
                  />
                </div>
              </li>
            ))}
          </ul>
        ),
      };
    });

  return (
    <>
      <Helmet>
        <title>{t("category_management")}</title>
      </Helmet>
      <Spin spinning={loading}>
        <div>
          <h2>{t("category_management")}</h2>
          <p>{t("add_edit_delete_category")}</p>

          <Row gutter={24} align="top">
            <Col span={9} style={{ marginTop: "20px" }}>
              <div
                style={{
                  padding: "20px",
                  border: "1px solid #f0f0f0",
                  borderRadius: "9px",
                  background: "#fafafa",
                }}
              >
                <Input
                  placeholder={t("name")}
                  name="name"
                  onChange={handleNewCategoryInputChange}
                  style={{ marginBottom: "10px" }}
                  className={
                    newCategoryFormErrors.name ? "ant-input-status-error" : ""
                  }
                  value={newCategoryForm.name}
                />
                {newCategoryFormErrors.name && (
                  <div style={{ color: "red", marginBottom: "10px" }}>
                    {newCategoryFormErrors.name}
                  </div>
                )}

                <Input.TextArea
                  placeholder={t("description")}
                  name="description"
                  onChange={handleNewCategoryInputChange}
                  style={{ marginBottom: "15px", height: "120px" }}
                  value={newCategoryForm.description}
                />

                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddCategory}
                  style={{ width: "100%", background: "#00a99d" }}
                >
                  {t("create_category")}
                </Button>
              </div>
            </Col>

            <Col span={15}>
              <Collapse
                accordion
                items={renderCategoryItems(categories)}
                style={{ marginTop: "20px" }}
              />
            </Col>
          </Row>

          {/* Modal for Editing Parent Category */}
          <Modal
            title={t("edit_category")}
            open={isEditParentModalVisible}
            onOk={handleEditParentCategory}
            onCancel={() => setIsEditParentModalVisible(false)}
          >
            <Input
              placeholder={t("subcategory_name")}
              name="name"
              value={newCategoryForm.name}
              onChange={handleNewCategoryInputChange}
              style={{ marginBottom: "10px" }}
              className={
                newCategoryFormErrors.name ? "ant-input-status-error" : ""
              }
            />
            {newCategoryFormErrors.name && (
              <div style={{ color: "red", marginBottom: "10px" }}>
                {newCategoryFormErrors.name}
              </div>
            )}
            <Input.TextArea
              placeholder={t("description")}
              name="description"
              value={newCategoryForm.description}
              onChange={handleNewCategoryInputChange}
            />
          </Modal>

          {/* Modal for Adding/Deleting Subcategories */}
          <Modal
            title={t("add_subcategory")}
            open={isSubcategoryModalVisible}
            onCancel={() => setIsSubcategoryModalVisible(false)}
            footer={null}
          >
            <div>
              <h2>
                <i>{parentForSubcategory?.name}</i>
              </h2>
              <b>{t("subcategories")}:</b>
              <ul style={{ marginBottom: "15px" }}>
                {parentForSubcategory?.children.map((sub) => (
                  <li
                    key={sub._id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>• {sub.name}</span>
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => handleDeleteSubcategory(sub._id)}
                      style={{
                        color: "#ff4d4f",
                        fontWeight: "bold",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                      className="delete-button2"
                    />
                  </li>
                ))}
              </ul>

              <Input
                placeholder={t("sub_category_name")}
                name="name"
                value={subcategoryForm.name}
                onChange={handleSubcategoryFormChange}
                style={{ marginBottom: "10px" }}
                ref={subcategoryNameInputRef}
                className={
                  subcategoryFormErrors.name ? "ant-input-status-error" : ""
                }
              />
              {subcategoryFormErrors.name && (
                <div style={{ color: "red", marginBottom: "10px" }}>
                  {subcategoryFormErrors.name}
                </div>
              )}

              <Input.TextArea
                placeholder={t("sub_category_description")}
                name="description"
                value={subcategoryForm.description}
                onChange={handleSubcategoryFormChange}
                ref={subcategoryDescriptionInputRef}
              />
              <div style={{ marginTop: "15px", textAlign: "right" }}>
                <Button
                  onClick={() => setIsSubcategoryModalVisible(false)}
                  style={{ marginRight: "10px" }}
                >
                  {t("cancel")}
                </Button>
                <Button type="primary" onClick={handleAddSubcategory}>
                  {t("save")}
                </Button>
              </div>
            </div>
          </Modal>

          {/* Modal for Editing Subcategory */}
          <Modal
            title={t("edit_subcategory")}
            open={isEditSubcategoryModalVisible}
            onOk={handleEditSubcategory}
            onCancel={() => setIsEditSubcategoryModalVisible(false)}
            cancelText={t("cancel")}
            okText={t("save")}
          >
            <Input
              placeholder={t("subcategory_name")}
              name="name"
              value={subcategoryForm.name}
              onChange={handleSubcategoryFormChange}
              style={{ marginBottom: "10px" }}
              ref={subcategoryNameInputRef}
              className={
                subcategoryFormErrors.name ? "ant-input-status-error" : ""
              }
            />
            {subcategoryFormErrors.name && (
              <div style={{ color: "red", marginBottom: "10px" }}>
                {subcategoryFormErrors.name}
              </div>
            )}

            <Input.TextArea
              placeholder={t("subcategory_description")}
              name="description"
              value={subcategoryForm.description}
              onChange={handleSubcategoryFormChange}
              ref={subcategoryDescriptionInputRef}
            />
          </Modal>
        </div>
      </Spin>
    </>
  );
};

export default CategoriesList;