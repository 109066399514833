import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  getAgeGroupsList,
  addAgeGroup,
  updateAgeGroup,
  deleteAgeGroup,
} from "../../api/ageGroup.api";
import { Table, Input, Button, message, Row, Col, Modal, Spin } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function AgeGroup() {
  const { t } = useTranslation("ageGroup");
  const [ageGroups, setAgeGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newAgeGroup, setNewAgeGroup] = useState("");
  const [editingAgeGroupId, setEditingAgeGroupId] = useState(null);
  const [editingAgeGroupName, setEditingAgeGroupName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchAgeGroups = async () => {
      try {
        const result = await getAgeGroupsList();
        setAgeGroups(result.data);
      } catch (error) {
        console.error(t("error_loading_age_groups"), error);
        setError(t("error_loading_age_groups"));
      } finally {
        setLoading(false);
      }
    };

    fetchAgeGroups();
  }, [t]);

  const handleAdd = async () => {
    if (!newAgeGroup) {
      message.error(t("error_adding_age_group"));
      return;
    }
    try {
      const result = await addAgeGroup({ name: newAgeGroup });
      setAgeGroups([...ageGroups, result.data]);
      setNewAgeGroup("");
      message.success(t("added_successfully"));
    } catch (error) {
      message.error(error.response?.data?.error || t("error_adding_age_group"));
    }
  };

  const handleDelete = (ageGroupId) => {
    Modal.confirm({
      title: t("delete_confirmation"),
      content: t("delete_warning"),
      okText: t("delete"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          const response = await deleteAgeGroup(ageGroupId);
          if (response.success) {
            message.success(t("deleted_successfully"));
            setAgeGroups(
              ageGroups.filter((ageGroup) => ageGroup._id !== ageGroupId)
            );
          }
        } catch (error) {
          message.error(error.response?.data?.error || t("delete_error"));
        }
      },
    });
  };

  const handleEdit = async () => {
    try {
      const result = await updateAgeGroup(editingAgeGroupId, {
        name: editingAgeGroupName,
      });
      setAgeGroups(
        ageGroups.map((ageGroup) =>
          ageGroup._id === editingAgeGroupId
            ? { ...ageGroup, name: result.data.name }
            : ageGroup
        )
      );
      message.success(t("updated_successfully"));
      setEditingAgeGroupId(null);
      setEditingAgeGroupName("");
    } catch (error) {
      message.error(error.response?.data?.error || t("edit_error"));
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const filteredAgeGroups = ageGroups.filter((ageGroup) =>
    ageGroup.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: t("age_group"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, ageGroup) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            paddingRight: "0px",
          }}
        >
          <Button
            className="edit"
            onClick={() => {
              setEditingAgeGroupId(ageGroup._id);
              setEditingAgeGroupName(ageGroup.name);
            }}
            style={{
              marginRight: "5px",
              borderColor: "#00a99d",
              color: "#00a99d",
            }}
          >
            <FaEdit /> {t("edit")}
          </Button>
          <Button
            danger
            className="delete"
            onClick={() => handleDelete(ageGroup._id)}
          >
            <FaTrash /> {t("delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("manage_age_group")}</title>
      </Helmet>

      <div style={{ marginBottom: "20px" }}>
        <h2>{t("manage_age_group")}</h2>
        <p>{t("add_edit_delete_age_group")}</p>
      </div>

      <Input.Search
        placeholder={t("search_age_group_placeholder")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: "25%", marginBottom: "20px" }}
      />
      <Row gutter={16}>
        <Col span={8}>
          <div
            style={{
              background: "#f9f9f9",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px" }}>{t("age_group")}</h4>

            <Input
              placeholder={t("add_here_placeholder")}
              value={newAgeGroup}
              onChange={(e) => setNewAgeGroup(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ width: "100%", backgroundColor: "#00bfa5" }}
            >
              {t("add_age_group")}
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table
              dataSource={filteredAgeGroups
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((ageGroup) => ({ ...ageGroup, key: ageGroup._id }))}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: filteredAgeGroups.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={t("edit_age_group")}
        open={!!editingAgeGroupId}
        onCancel={() => {
          setEditingAgeGroupId(null);
          setEditingAgeGroupName("");
        }}
        onOk={handleEdit}
        cancelText={t("cancel2")} 
        okText={t("confirm")}
      >
        <Input
          value={editingAgeGroupName}
          onChange={(e) => setEditingAgeGroupName(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default AgeGroup;
