import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  getPublishersList,
  addPublisher,
  updatePublisher,
  deletePublisher,
} from "../../api/publisher.api";
import { Table, Input, Button, message, Row, Col, Modal, Spin } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Publisher() {
  const { t } = useTranslation("pubListherManager");
  const [publishers, setPublishers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPublisher, setNewPublisher] = useState("");
  const [editingPublisherId, setEditingPublisherId] = useState(null);
  const [editingPublisherName, setEditingPublisherName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchPublishers = async () => {
      try {
        const result = await getPublishersList();
        setPublishers(result.data);
      } catch (error) {
        console.error("Lỗi khi lấy danh sách nhà xuất bản:", error);
        setError("Không thể tải danh sách nhà xuất bản.");
      } finally {
        setLoading(false);
      }
    };

    fetchPublishers();
  }, []);

  const handleAdd = async () => {
    if (!newPublisher) {
      message.error(t("pleaseEnterNewPublisherName"));
      return;
    }
    try {
      const result = await addPublisher({ name: newPublisher });
      setPublishers([...publishers, result.data]);
      setNewPublisher("");
      message.success(t("successAdd"));
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    }
  };

  const handleDelete = (publisherId) => {
    Modal.confirm({
      title: t("confirmDelete"),
      content: t("confirmDeleteMessage"),
      okText: t("confirm"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          const response = await deletePublisher(publisherId);
          if (response.success) {
            message.success(t("successDelete"));
            setPublishers(
              publishers.filter((publisher) => publisher._id !== publisherId)
            );
          }
        } catch (error) {
          const errorMessage = error.response?.data?.error;
          message.error(errorMessage);
        }
      },
    });
  };

  const handleEdit = async () => {
    if (!editingPublisherName) {
      message.error(t("pleaseEnterNewPublisherName"));
      return;
    }
    try {
      const result = await updatePublisher(editingPublisherId, {
        name: editingPublisherName,
      });
      setPublishers(
        publishers.map((publisher) =>
          publisher._id === editingPublisherId
            ? { ...publisher, name: result.data.name }
            : publisher
        )
      );
      message.success(t("successEdit"));
      setEditingPublisherId(null);
      setEditingPublisherName("");
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const filteredPublishers = publishers.filter((publisher) =>
    publisher.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: t("publisherName"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, publisher) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            paddingRight: "0px",
          }}
        >
          <Button
            className="edit"
            onClick={() => {
              setEditingPublisherId(publisher._id);
              setEditingPublisherName(publisher.name);
            }}
            style={{
              marginRight: "5px",
              borderColor: "#00a99d",
              color: "#00a99d",
            }}
          >
            <FaEdit /> {t("edit")}
          </Button>
          <Button
            className="delete"
            onClick={() => handleDelete(publisher._id)}
            danger
          >
            <FaTrash /> {t("delete")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>

      <div style={{ marginBottom: "20px" }}>
        <h2>{t("title")}</h2>
        <p>
          {t("addPublisher")} , {t("editPublisher")}
        </p>
      </div>

      <Input.Search
        placeholder={t("searchPublisher")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: "25%", marginBottom: "20px" }}
      />

      <Row gutter={16}>
        <Col span={8}>
          <div
            style={{
              background: "#f9f9f9",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px", color: "#333" }}>
              {t("publisherName")}
            </h4>
            <Input
              placeholder={t("add")}
              value={newPublisher}
              onChange={(e) => setNewPublisher(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ width: "100%", backgroundColor: "#00bfa5" }}
            >
              {t("addPublisher")}
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table
              dataSource={filteredPublishers
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((publisher) => ({ ...publisher, key: publisher._id }))}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: filteredPublishers.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={t("editPublisher")}
        open={!!editingPublisherId}
        onCancel={() => {
          setEditingPublisherId(null);
          setEditingPublisherName("");
        }}
        onOk={handleEdit}
      >
        <Input
          value={editingPublisherName}
          onChange={(e) => setEditingPublisherName(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default Publisher;
