import { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Button,
  Input,
  Select,
  Search,
  Pagination,
  Row,
  Col,
} from "antd";
import "./OrderList.scss";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { getOrders } from "../../../api/order.api";
import { formattedDate } from "../../../components/common/FormattedDate";
import { formatCurrency } from "../../../components/common/FormatPrice";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Option } = Select;

const OrderList = () => {
  const { t } = useTranslation("orderList");

  const [orderData, setOrderData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredStatus, setFilteredStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [error, setError] = useState(null);
  const { Search } = Input;

  const fetchOrderData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Vui lòng đăng nhập để xem đơn hàng.");
        return;
      }
      const response = await getOrders(token);
      if (response.success && Array.isArray(response.data)) {
        setOrderData(response.data.reverse());
      }
    } catch (error) {
      console.error("Không thể lấy dữ liệu đơn hàng:", error);
      setError("Không thể lấy dữ liệu đơn hàng");
    }
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  const filteredData = orderData.filter((order) => {
    const matchesSearch = order.code
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesStatus = filteredStatus
      ? order.status === filteredStatus
      : true;
    return matchesSearch && matchesStatus;
  });

  const columns = [
    {
      title: t("Mã đơn hàng"),
      dataIndex: "code",
      key: "code",
      align: "center",
    },
    {
      title: t("Tên khách hàng"),
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
      align: "center",
    },
    {
      title: t("Tổng tiền"),
      dataIndex: "total_price",
      key: "total_price",
      align: "center",
      render: (text) => <span>{formatCurrency(text)}</span>,
    },
    {
      title: t("Phương thức thanh toán"),
      dataIndex: "payment_method",
      key: "payment_method",
      render: (text, record) => {
        const paymentMethod = text.toUpperCase();
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
            }}
          >
            <Tag color={paymentMethod === "COD" ? "orange" : "blue"}>
              {paymentMethod}
            </Tag>
            {paymentMethod === "VNPAY" && (
              <div style={{ marginTop: "4px" }}>
                <Tag
                  color={
                    record.payment_status === "Chưa thanh toán" ||
                    record.payment_status === "Unpaid"
                      ? "lightgray"
                      : "green"
                  }
                >
                  {t(`${record.payment_status}`)}
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("Trạng thái"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color;
        let label = status;
        switch (status) {
          case "Chờ xác nhận":
            color = "volcano";
            label = t("Chờ xác nhận"); // Dịch từ key JSON
            break;
          case "Đã xác nhận":
            color = "green";
            label = t("Đã xác nhận");
            break;
          case "Đang chuẩn bị hàng":
            color = "cyan";
            label = t("Đang chuẩn bị hàng");
            break;
          case "Đang giao":
            color = "gold";
            label = t("Đang giao");
            break;
          case "Đã giao":
            color = "green";
            label = t("Đã giao");
            break;
          case "Giao thất bại":
            color = "red";
            label = t("Giao thất bại");
            break;
          case "Hủy đơn hàng":
            color = "red";
            label = t("Hủy đơn hàng");
            break;
          default:
            color = "default";
            label = t("default");
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: t("Ngày đặt hàng"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => <span>{formattedDate(date)}</span>,
    },
    {
      title: t("Hành động"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Link to={`/order-detail/${record.code}`}>
          <Button style={{ borderColor: "#00a99d", color: "#00a99d" }}>
            <AiOutlineEye /> {t("Chi tiết")}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("Danh sách đơn hàng")}</title>
      </Helmet>
      <div className="order-list-container">
        <h1 className="order-list-title">{t("Danh sách đơn hàng")}</h1>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Row
          gutter={8}
          className="order-list-filters"
          justify="space-between"
          align="middle"
        >
          <Col span={6}>
            <Search
              placeholder={t("Tìm kiếm...")}
              allowClear
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 200 }}
            />
          </Col>
          <Col span={18}>
            <div className="right-align-filters">
              <Select
                placeholder="Chọn trạng thái"
                onChange={(value) => {
                  setFilteredStatus(value === "Tất cả trạng thái" ? "" : value);
                }}
                allowClear
                value={filteredStatus || "Tất cả trạng thái"}
                style={{
                  width: "200px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                  marginRight: "10px",
                }}
                size="middle"
                popupMatchSelectWidth={false}
              >
                <Option value="Tất cả trạng thái">{t("Hiện tất cả")}</Option>{" "}
                <Option
                  value="Chờ xác nhận"
                  disabled={filteredStatus === "Chờ xác nhận"}
                >
                  {t("Chờ xác nhận")}
                </Option>
                <Option
                  value="Đã xác nhận"
                  disabled={filteredStatus === "Đã xác nhận"}
                >
                  {t("Đã xác nhận")}
                </Option>
                <Option
                  value="Đang chuẩn bị hàng"
                  disabled={filteredStatus === "Đang chuẩn bị hàng"}
                >
                  {t("Đang chuẩn bị hàng")}
                </Option>
                <Option
                  value="Đang giao"
                  disabled={filteredStatus === "Đang giao"}
                >
                  {t("Đang giao")}
                </Option>
                <Option value="Đã giao" disabled={filteredStatus === "Đã giao"}>
                  {t("Đã giao")}
                </Option>
                <Option
                  value="Hủy đơn hàng"
                  disabled={filteredStatus === "Hủy đơn hàng"}
                >
                  {t("Đơn hàng bị hủy")}
                </Option>
                <Option
                  value="Giao thất bại"
                  disabled={filteredStatus === "Giao thất bại"}
                >
                  {t("Giao thất bại")}
                </Option>
              </Select>

              <Select
                placeholder={t("Show 5")}
                style={{
                  width: "120px",
                  borderRadius: "8px",
                  fontSize: "14px",
                  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                }}
                size="middle"
                onChange={(value) =>
                  setItemsPerPage(
                    value === "all" ? orderData.length : Number(value)
                  )
                }
                popupMatchSelectWidth={false}
              >
                <Option value="5">{t("Show")} 5</Option>
                <Option value="10">{t("Show")} 10</Option>
                <Option value="15">{t("Show")} 15</Option>
                <Option value="20">{t("Show")} 20</Option>
                <Option value="all">{t("Hiện tất cả")}</Option>
              </Select>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={filteredData.slice(
            (currentPage - 1) * itemsPerPage,
            currentPage * itemsPerPage
          )}
          pagination={false}
          rowKey="_id"
          className="order-list-table"
        />
        {filteredData.length > itemsPerPage && (
          <Pagination
            style={{ marginTop: "20px", textAlign: "right", display: "block" }}
            current={currentPage}
            total={filteredData.length}
            pageSize={itemsPerPage}
            onChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </>
  );
};

export default OrderList;
