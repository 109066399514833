import { get, post } from "../utils/request";

export const getNewOrders = async () => {
  const result = await get("notifications/new-orders");
  return result;
};

export const resetOrdersCount = async () => {
  const result = await post("notifications/reset-orders-count");
  return result;
};

export const getPostsPending = async () => {
  const result = await get("notifications/posts-pending");
  return result;
};

export const resetPostsPending = async () => {
  const result = await post("notifications/reset-posts-pending");
  return result;
};