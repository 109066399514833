import { get, patch } from "../utils/request";

export const getOrders = async (token) => {
    const result = await get("orders", token);
    return result;
};

export const getOrderDetail = async (code, token) => {
    const result = await get(`orders/${code}`, token);
    return result;
};

export const updateOrderStatus = async (id, status) => {
    const result = await patch(`orders/${id}/status`, { status });
    return result;
};