import { get, post, put, del } from "../utils/request";

export const getPublishersList = async () => {
  const result = await get("publishers");
  return result;
};

export const addPublisher = async (publisherData) => {
  const result = await post("publishers", publisherData);
  return result;
};

export const updatePublisher = async (id, publisherData) => {
  const result = await put(`publishers/${id}`, publisherData);
  return result;
};

export const deletePublisher = async (id) => {
  const result = await del(`publishers/${id}`);
  return result;
};