import { get, post, put, del } from '../utils/request';

export const getAgeGroupsList = async () => {
  const result = await get('age-group');
  return result;
};

export const addAgeGroup = async (ageGroupData) => {
  const result = await post('age-group', ageGroupData);
  return result;
};

export const updateAgeGroup = async (id, ageGroupData) => {
  const result = await put(`age-group/${id}`, ageGroupData);
  return result;
};

export const deleteAgeGroup = async (id) => {
  const result = await del(`age-group/${id}`);
  return result;
};