import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Input,
  Pagination,
  Select,
  Switch,
  Modal,
  message,
  Dropdown,
  Menu,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  getPostsListByAdmin,
  deletePost,
  togglePublishPost,
} from "../../../api/post.api";
import { EllipsisOutlined, DeleteOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Option } = Select;
const { Search } = Input;

const PostAdminList = () => {
  const { t } = useTranslation("postAdminList");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewFilter, setViewFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [pagination.current, pagination.pageSize]);

  const fetchData = async () => {
    const response = await getPostsListByAdmin(
      pagination.current,
      pagination.pageSize
    );
    setData(response.data.reverse());
    setPagination((prev) => ({
      ...prev,
      total: response.total,
    }));
  };

  const handleDelete = async (id) => {
    await deletePost(id);
    setData(data.filter((post) => post._id !== id));
    message.success(t("deleteSuccess"));
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("confirmDeleteTitle"),
      content: t("confirmDeleteContent"),
      okText: t("confirmDeleteOkText"),
      okType: "danger",
      cancelText: t("confirmDeleteCancelText"),
      onOk: () => handleDelete(id),
    });
  };

  const handleTogglePublish = async (id, isPublish) => {
    await togglePublishPost(id, { is_publish: !isPublish });
    const updatedData = data.map((post) =>
      post._id === id ? { ...post, is_publish: !isPublish } : post
    );
    setData(updatedData);
    message.success(
      `${
        isPublish ? t("unpublishSuccess") : t("publishSuccess")
      }  ${isPublish} `,
      2
    );
  };

  const handleViewFilter = (value) => {
    setViewFilter(value);
  };

  const handleStatusFilter = (value) => {
    setStatusFilter(value);
  };

  const filteredData = data.filter(
    (post) =>
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (viewFilter ? post.views >= Number(viewFilter) : true) &&
      (statusFilter ? post.is_publish.toString() === statusFilter : true)
  );

  const columns = [
    {
      title: t("thumbnail"),
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (thumbnail) => (
        <img src={thumbnail} alt="Thumbnail" style={{ width: 100 }} />
      ),
    },
    {
      title: t("title1"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 300,
    },
    {
      title: t("views"),
      dataIndex: "views",
      align: "center",
      key: "views",
    },
    {
      title: t("admin"),
      dataIndex: "admin",
      align: "center",
      key: "full_name",
      render: (admin) => (admin ? admin.full_name : "Không xác định"),
    },
    {
      title: t("is_publish"),
      key: "is_publish",
      align: "center",
      render: (_, record) => (
        <Switch
          checked={record.is_publish}
          onChange={() => handleTogglePublish(record._id, record.is_publish)}
        />
      ),
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (_, record) => {
        const menuItems = [
          {
            key: "detail",
            icon: <EyeOutlined />,
            label: (
              <span onClick={() => navigate(`/post-admin-detail/${record._id}`)}>
                {t("detail")}
              </span>
            ),
          },
          {
            key: "edit",
            icon: <EditOutlined />,
            label: (
              <span onClick={() => navigate(`/edit-admin-post/${record._id}`)}>
                {t("edit")}
              </span>
            ),
          },
          {
            key: "delete",
            icon: <DeleteOutlined />,
            label: (
              <span onClick={() => confirmDelete(record._id)} style={{ color: "red" }}>
                {t("delete")}
              </span>
            ),
            danger: true,
          },
        ];

        return (
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Button style={{width: "30px"}}>
              <EllipsisOutlined style={{ fontSize: "16px", color: "#333" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const handlePageChange = (page, pageSize) => {
    setPagination({
      ...pagination,
      current: page,
      pageSize: pageSize,
    });
  };

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <div>
        <h2 style={{ marginBottom: 20 }}>{t("title")}</h2>
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 20 }}
        >
          <Search
            placeholder={t("searchPlaceholder")}
            allowClear
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: 200 }}
          />
          <Select
            placeholder={t("viewFilterPlaceholder")}
            value={viewFilter}
            onChange={handleViewFilter}
            style={{ width: 200, marginLeft: 10 }}
          >
            <Option value="">{t("viewFilterPlaceholder")}</Option>
            <Option value="0">0+</Option>
            <Option value="50">50+</Option>
            <Option value="100">100+</Option>
            <Option value="200">200+</Option>
          </Select>
          <Select
            placeholder={t("statusFilterPlaceholder")}
            value={statusFilter}
            onChange={handleStatusFilter}
            style={{ width: 200, marginLeft: 10 }}
          >
            <Option value="">{t("all")}</Option>
            <Option value="true">{t("show")}</Option>
            <Option value="false">{t("hide")}</Option>
          </Select>
        </div>
        <Table
          dataSource={filteredData}
          columns={columns}
          rowKey="_id"
          pagination={false}
        />
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default PostAdminList;
