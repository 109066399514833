import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  getAuthorsList,
  addAuthor,
  updateAuthor,
  deleteAuthor,
} from "../../api/author.api";
import { Table, Input, Button, message, Row, Col, Modal, Spin } from "antd";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function Author() {
  const { t } = useTranslation("author"); 
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newAuthor, setNewAuthor] = useState("");
  const [editingAuthorId, setEditingAuthorId] = useState(null);
  const [editingAuthorName, setEditingAuthorName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const result = await getAuthorsList();
        setAuthors(result.data);
      } catch (error) {
        console.error(t("error_loading_authors"), error);
        setError(t("error_loading_authors"));
      } finally {
        setLoading(false);
      }
    };
    fetchAuthors();
  }, [t]);

  const handleAdd = async () => {
    if (!newAuthor) {
      message.error(t("error_adding_author"));
      return;
    }
    try {
      const result = await addAuthor({ name: newAuthor });
      setAuthors([...authors, result.data]);
      setNewAuthor("");
      message.success(t("added_successfully"));
    } catch (error) {
      message.error(error.response?.data?.error || t("error_adding_author"));
    }
  };

  const handleDelete = (authorId) => {
    Modal.confirm({
      title: t("delete_confirmation"),
      content: t("delete_warning"),
      okText: t("delete_author"),
      okType: "danger",
      cancelText: t("cancel"),
      onOk: async () => {
        try {
          const response = await deleteAuthor(authorId);
          if (response.success) {
            message.success(t("deleted_successfully"));
            setAuthors(authors.filter((author) => author._id !== authorId));
          }
        } catch (error) {
          message.error(
            error.response?.data?.error || t("error_deleting_author")
          );
        }
      },
    });
  };

  const handleEdit = async () => {
    if (!editingAuthorName) {
      message.error(t("error_updating_author"));
      return;
    }
    try {
      const result = await updateAuthor(editingAuthorId, {
        name: editingAuthorName,
      });
      setAuthors(
        authors.map((author) =>
          author._id === editingAuthorId
            ? { ...author, name: result.data.name }
            : author
        )
      );
      message.success(t("updated_successfully"));
      setEditingAuthorId(null);
      setEditingAuthorName("");
    } catch (error) {
      message.error(error.response?.data?.error || t("error_updating_author"));
    }
  };

  if (loading) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const filteredAuthors = authors.filter((author) =>
    author.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: t("author_name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (text, author) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            paddingRight: "0px",
          }}
        >
          <Button
            className="edit"
            onClick={() => {
              setEditingAuthorId(author._id);
              setEditingAuthorName(author.name);
            }}
            style={{
              marginRight: "8px",
              borderColor: "#00a99d",
              color: "#00a99d",
            }}
          >
            <FaEdit /> {t("edit_author")}
          </Button>
          <Button
            className="delete"
            onClick={() => handleDelete(author._id)}
            danger
          >
            <FaTrash /> {t("delete_author")}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("manage_author")}</title>
      </Helmet>
      <div style={{ marginBottom: "20px" }}>
        <h2>{t("manage_author")}</h2>
        <p>{t("add_edit_delete_author")}</p>
      </div>
      <Input.Search
        placeholder={t("search_author_placeholder")}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ width: "25%", marginBottom: "20px" }}
      />
      <Row gutter={16}>
        <Col span={8}>
          <div
            style={{
              background: "#f9f9f9",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h4 style={{ marginBottom: "10px" }}>{t("author_name")}</h4>
            <Input
              placeholder={t("add_author")}
              value={newAuthor}
              onChange={(e) => setNewAuthor(e.target.value)}
              style={{ marginBottom: "10px" }}
            />
            <Button
              type="primary"
              onClick={handleAdd}
              style={{ width: "100%", backgroundColor: "#00bfa5" }}
            >
              {t("add_author")}
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <div
            style={{
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table
              dataSource={filteredAuthors
                .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                .map((author) => ({ ...author, key: author._id }))}
              columns={columns}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: filteredAuthors.length,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title={t("edit_author")}
        open={!!editingAuthorId}
        onCancel={() => {
          setEditingAuthorId(null);
          setEditingAuthorName("");
        }}
        onOk={handleEdit}
      >
        <Input
          value={editingAuthorName}
          onChange={(e) => setEditingAuthorName(e.target.value)}
        />
      </Modal>
    </>
  );
}

export default Author;
