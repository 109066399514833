import React, { useState } from "react";
import { Button, Form, Input, Typography, Card, message, Spin } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { getAdminInfo, login } from "../../api/admin.api";
import "./Login.scss";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useAdmin } from "../../contexts/getAdminInfo";
import Loading from "../../components/common/Loading";

const { Title } = Typography;

function Login() {
  const navigate = useNavigate();
  const { setAdminInfo, isLoading } = useAdmin();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async (values) => {
    try {
      setIsSubmitting(true);
      const response = await login({
        user_name: values.user_name,
        password: values.password,
      });

      if (response?.status === 200) {
        const token = response.token;
        localStorage.setItem("token", token);

        const adminInfo = await getAdminInfo(token);
        if (adminInfo.data) {
          setAdminInfo(adminInfo.data);
          message.success("Đăng nhập thành công!");

          setTimeout(() => {
            setIsSubmitting(false);
            navigate("/");
          }, 1400);
        } else {
          setIsSubmitting(false);
          message.error(
            "Không thể lấy thông tin người dùng, vui lòng thử lại."
          );
        }
      } else {
        setIsSubmitting(false);
        message.error("Đăng nhập không thành công, vui lòng thử lại.");
      }
    } catch {
      setIsSubmitting(false);
      message.error(
        "Tên người dùng hoặc mật khẩu không chính xác. Vui lòng thử lại."
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Đăng nhập quản trị</title>
      </Helmet>

      {isLoading || isSubmitting ? (
        <Loading />
      ) : (
        <div className="login-page">
          <div className="login-right">
            <Card bordered={false} style={{boxShadow: "0 0 10px 2px #00a99d"}}>
              <div className="login-header">
                <img
                  src="/assets/images/logo/logo-11.png"
                  alt="BookVibe Logo"
                  className="login-logo"
                />
                <Title level={2} className="login-title">
                  <b style={{color: "rgb(56 50 50)"}}>Welcome Back!</b>
                </Title>
              </div>
              <Form
                name="admin_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleLogin}
              >
                <Form.Item
                  name="user_name"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username"
                    className="input-field"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                    className="input-field"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    disabled={isLoading}
                  >
                    <Spin spinning={isLoading} size="small">
                      Đăng nhập
                    </Spin>
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      )}

      <div className="sorry">
        <img src="/assets/images/logo/logo-11.png" width={300}/>
        <h1 className="marquee-text">Website hiện chưa được hỗ trợ trên thiết bị này !</h1>
      </div>
    </>
  );
}

export default Login;
