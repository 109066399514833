export const timeAgo = (date, t) => {
  const now = new Date();
  const past = new Date(date);
  const diff = Math.round((now - past) / 1000);

  if (diff < 60) return t('timeAgo.seconds', { count: diff });
  if (diff < 3600) return t('timeAgo.minutes', { count: Math.floor(diff / 60) });
  if (diff < 86400) return t('timeAgo.hours', { count: Math.floor(diff / 3600) });
  return t('timeAgo.days', { count: Math.floor(diff / 86400) });
};
