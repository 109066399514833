import { get, patch, del } from "../utils/request"; 

export const getUsersList = async () => {
  const result = await get("auth/users"); 
  return result;
};

export const updateUserStatus = async (id, isBlock) => {
  const result = await patch(`auth/user/${id}/status`, { is_blocked: isBlock });
  return result;
};

export const updateUser = async (id, data) => {
  const result = await patch(`auth/users/${id}`, data); 
  return result;
};

export const deleteUser = async (id) => {
  const result = await del(`auth/user/${id}`); 
  return result;
};
