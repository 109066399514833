import { useEffect, useState } from "react";
import {
  Table,
  Button,
  Input,
  Pagination,
  Select,
  Modal,
  message,
  Dropdown,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import { getPostsList, deletePost } from "../../../api/post.api";
import "./PostList.scss";
import { EllipsisOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Option } = Select;
const { Search } = Input;

const PostList = () => {
  const { t } = useTranslation("postList");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewFilter, setViewFilter] = useState("");
  const [approvalStatusFilter, setApprovalStatusFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await getPostsList();
      setData(response.data.reverse());
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    await deletePost(id);
    setData(data.filter((post) => post._id !== id));
    message.success(t("deleteSuccess"));
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: t("deleteConfirmTitle"),
      content: t("deleteConfirmContent"),
      okText: t("deleteConfirmOkText"),
      okType: "danger",
      cancelText: t("deleteConfirmCancelText"),
      onOk: () => handleDelete(id),
    });
  };

  const handleViewFilter = (value) => {
    setViewFilter(value);
    setCurrentPage(1);
  };

  const handleApprovalStatusFilter = (value) => {
    setApprovalStatusFilter(value);
    setCurrentPage(1);
  };

  const filteredData = data.filter(
    (post) =>
      post.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (viewFilter ? post.views >= Number(viewFilter) : true) &&
      (approvalStatusFilter
        ? post.approval_status === approvalStatusFilter
        : true)
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      title: t("thumbnail"),
      dataIndex: "thumbnail",
      key: "thumbnail",

      render: (thumbnail) => (
        <img src={thumbnail} alt={t("columns.thumbnail")} style={{ width: 100 }} />
      ),
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 300,
    },
    {
      title: t("views"),
      dataIndex: "views",
      align: "center",
      key: "views",
    },
    {
      title: t("postedBy"),
      dataIndex: "user",
      key: "full_name",
      align: "center",
      render: (user) => (user ? user.full_name : t("columns.unknown")),
    },
    {
      title: t("approvalStatus"),
      dataIndex: "approval_status",
      key: "approval_status",
      align: "center",
      render: (approval_status) => {
        let color;
        let text = approval_status;

        switch (approval_status) {
          case "Đã duyệt":
            color = "green";
            text = t("approved");
            break;
          case "Chờ duyệt":
            color = "orange";
            text = t("pending");
            break;
          case "Từ chối duyệt":
            color = "red";
            text = t("rejected");
            break;
          default:
            color = "default";
            text = t("unknown");
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (_, record) => {
        const menuItems = [
          {
            key: "detail",
            icon: <EyeOutlined />,
            label: (
              <span onClick={() => navigate(`/post-detail/${record._id}`)}>
                {t("details")}
              </span>
            ),
          },
          {
            key: "delete",
            icon: <DeleteOutlined />,
            label: (
              <span onClick={() => confirmDelete(record._id)} style={{ color: "red" }}>
                {t("delete")}
              </span>
            ),
            danger: true,
          },
        ];

        return (
          <Dropdown menu={{ items: menuItems }} trigger={["click"]}>
            <Button style={{ width: "30px" }}>
              <EllipsisOutlined style={{ fontSize: "16px", color: "#333" }} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <div>
        <h2 style={{ marginBottom: 20 }}>{t("title")}</h2>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
          <Search
            placeholder={t("searchPlaceholder")}
            allowClear
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
            style={{ width: 200 }}
          />
          <Select
            placeholder={t("viewFilterPlaceholder")}
            value={viewFilter}
            onChange={handleViewFilter}
            style={{ width: 200, marginLeft: 10 }}
          >
            <Option value="">{t("views")}</Option>
            <Option value="0">0+</Option>
            <Option value="50">50+</Option>
            <Option value="100">100+</Option>
            <Option value="200">200+</Option>
          </Select>
          <Select
            placeholder={t("approvalStatusFilterPlaceholder")}
            value={approvalStatusFilter}
            onChange={handleApprovalStatusFilter}
            style={{ width: 200, marginLeft: 10 }}
          >
            <Option value="">{t("all")}</Option>
            <Option value="Đã duyệt">{t("approved")}</Option>
            <Option value="Chờ duyệt">{t("pending")}</Option>
            <Option value="Từ chối duyệt">{t("rejected")}</Option>
          </Select>
        </div>
        <Table dataSource={paginatedData} columns={columns} rowKey="_id" pagination={false} />
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
          <Pagination current={currentPage} pageSize={pageSize} total={filteredData.length} onChange={handlePageChange} />
        </div>
      </div>
    </>
  );
};

export default PostList;