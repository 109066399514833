import { useState, useEffect } from "react";
import {
  Table,
  message,
  Modal,
  Input,
  Button,
  Select,
  Dropdown,
  Switch,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getBooksList,
  deleteStatus,
  toggleHiddenStatus,
} from "../../../api/book.api";
import { getAuthorsList } from "../../../api/author.api";
import { getCategoriesList } from "../../../api/category.api";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

const BookList = () => {
  const { t } = useTranslation("bookList");
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterHiddenStatus, setFilterHiddenStatus] = useState("all");
  const [filterAuthor, setFilterAuthor] = useState("all");
  const [filterCategory, setFilterCategory] = useState("all");
  const [filterSales, setFilterSales] = useState("all"); // Đã sửa lại
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;
  const navigate = useNavigate();

  const fetchBooks = async () => {
    setLoading(true);
    try {
      const response = await getBooksList();
      if (response?.success && Array.isArray(response.data)) {
        setBooks(response.data);
      }
    } catch (error) {
      message.error(t("error_loading_books"));
    } finally {
      setLoading(false);
    }
  };

  const fetchDropdownData = async () => {
    try {
      const [authorsRes, categoriesRes] = await Promise.all([
        getAuthorsList(),
        getCategoriesList(),
      ]);
      setAuthors(authorsRes.data);
      setCategories(categoriesRes.data);
    } catch {
      message.error(t("error_loading_lists"));
    }
  };

  useEffect(() => {
    fetchBooks();
    fetchDropdownData();
  }, []);

  const handleDelete = (bookId) => {
    confirm({
      title: t("delete_confirmation_title"),
      content: t("delete_confirmation_content"),
      okText: t("delete_confirmation_ok"),
      okType: "danger",
      cancelText: t("delete_confirmation_cancel"),
      onOk: async () => {
        try {
          const response = await deleteStatus(bookId);
          if (response.success) {
            message.success(t("delete_success"));
            fetchBooks();
          }
        } catch {
          message.error(t("delete_error"));
        }
      },
    });
  };

  const handleToggleHidden = async (bookId, currentStatus) => {
    try {
      const result = await toggleHiddenStatus(bookId, !currentStatus);
      if (result.success) {
        message.success(t("update_hidden_status_success"));
        fetchBooks();
      }
    } catch {
      message.error(t("update_hidden_status_error"));
    }
  };

  const filteredBooks = books.filter((book) => {
    const matchesSearch = book.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesHiddenStatus =
      filterHiddenStatus === "all" ||
      (filterHiddenStatus === "hidden" && book.is_hidden) ||
      (filterHiddenStatus === "visible" && !book.is_hidden);
    const matchesAuthor =
      filterAuthor === "all" || book.author?._id === filterAuthor;
    const matchesCategory =
      filterCategory === "all" || book.category?._id === filterCategory;

    return (
      matchesSearch && matchesHiddenStatus && matchesAuthor && matchesCategory
    );
  });

  const filteredBySales = filteredBooks.filter((book) => {
    if (filterSales === "highest") {
      return book.sold >= 50;
    }
    if (filterSales === "lowest") {
      return book.sold <= 20;
    }
    return true; 
  }).sort((a, b) => {
    if (filterSales === "highest") {
      return b.sold - a.sold;
    }
    if (filterSales === "lowest") {
      return a.sold - b.sold;
    }
    return 0;  
  });
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedBooks = filteredBySales.slice(
    startIndex,
    startIndex + pageSize
  );

  return (
    <>
      <Helmet>
        <title>{t("page_subtitle")}</title>
      </Helmet>

      <h1 style={{ padding: "20px 0px" }}>{t("filter_all_books")}</h1>

      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Search
            placeholder={t("search_placeholder")}
            allowClear
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: 200 }}
          />

          <Select
            defaultValue="all"
            onChange={setFilterHiddenStatus}
            style={{ width: 150 }}
          >
            <Option value="all">{t("filter_all_books")}</Option>
            <Option value="hidden">{t("filter_hidden_books")}</Option>
            <Option value="visible">{t("filter_visible_books")}</Option>
          </Select>

          <Select
            defaultValue="all"
            onChange={setFilterAuthor}
            style={{ width: 150 }}
          >
            <Option value="all">{t("filter_all_authors")}</Option>
            {authors.map((author) => (
              <Option key={author._id} value={author._id}>
                {author.name}
              </Option>
            ))}
          </Select>
          <Select
            defaultValue="all"
            onChange={setFilterCategory}
            style={{ width: 150 }}
          >
            <Option value="all">{t("filter_all_categories")}</Option>
            {categories
              .filter(
                (category) => category.children && category.children.length > 0
              )
              .flatMap((category) =>
                category.children.map((subCategory) => (
                  <Option key={subCategory._id} value={subCategory._id}>
                    {subCategory.name}
                  </Option>
                ))
              )}
          </Select>

          <Select
            defaultValue="all"
            onChange={setFilterSales}
            style={{ width: 150 }}
          >
            <Option value="all">{t("filter_all_sales")}</Option>
            <Option value="highest">{t("filter_high_sales")}</Option>
            <Option value="lowest">{t("filter_low_sales")}</Option>
          </Select>
        </div>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => navigate("/add-book")}
          style={{ backgroundColor: "#00a99d", borderColor: "#00a99d" }}
        >
          {t("add_new_button")}
        </Button>
      </div>

      <Table
        columns={[
          {
            title: t("table_column_image"),
            dataIndex: "images",
            key: "images",
            render: (images) => (
              <img
                src={images[0]}
                alt="Book cover"
                width={50}
                height={50}
                style={{ objectFit: "cover" }}
              />
            ),
          },
          {
            title: t("table_column_title"),
            dataIndex: "title",
            key: "title",
            width: 300,
            align: "center",
          },
          {
            title: t("table_column_stock"),
            dataIndex: "stock",
            key: "stock",
            align: "center",
          },
          {
            title: t("table_column_author"),
            dataIndex: ["author", "name"],
            key: "author",
            align: "center",
          },
          {
            title: t("table_column_category"),
            dataIndex: ["category", "name"],
            key: "category",
            align: "center",
          },
          {
            title: t("table_column_sales"),
            dataIndex: "sold",
            key: "sold",
            align: "center",
            render: (sold) => <span>{sold}</span>,
          },
          {
            title: t("table_column_hidden"),
            dataIndex: "is_hidden",
            key: "is_hidden",
            align: "center",
            render: (is_hidden, record) => (
              <Switch
                checked={is_hidden}
                onChange={() => handleToggleHidden(record._id, is_hidden)}
              />
            ),
          },
          {
            title: t("table_column_actions"),
            key: "actions",
            align: "center",
            render: (_, record) => {
              const menuItems = [
                {
                  key: "edit",
                  label: t("menu_edit"),
                  icon: <EditOutlined />,
                  onClick: () => navigate(`/edit-book/${record._id}`),
                },
                {
                  key: "detail",
                  label: t("menu_detail"),
                  icon: <EyeOutlined />,
                  onClick: () => navigate(`/book-detail/${record._id}`),
                },
                {
                  key: "delete",
                  label: t("menu_delete"),
                  icon: <DeleteOutlined />,
                  style: { color: 'red' },
                  onClick: () => handleDelete(record._id),
                },
              ];
              return (
                <Dropdown menu={{ items: menuItems }}>
                  <Button
                    icon={<EllipsisOutlined />}
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              );
            },
          },
        ]}
        dataSource={paginatedBooks}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: filteredBySales.length,
          onChange: setCurrentPage,
        }}
      />
    </>
  );
};

export default BookList;
