import { Helmet } from "react-helmet";
import { Button, Layout, Typography } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { Content } = Layout;
const { Title, Text } = Typography;

function NotFound() {
  const { t } = useTranslation("notFound");

  return (
    <>
      <Helmet>
        <title>{t("not_found")}</title>
      </Helmet>

      <Layout style={{ height: "100vh" }}>
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "center",
            padding: "50px 0",
          }}
        >
          <Title level={1} style={{ margin: 0 }}>
            {t("not_found")}
          </Title>
          <Title level={4} style={{ margin: "10px 0" }}>
            {t("page_not_found_message")}
          </Title>
          <Text type="secondary">{t("not_found_description")}</Text>
          <Button
            type="primary"
            icon={<HomeOutlined />}
            href="/"
            style={{ marginTop: "20px" }}
          >
            {t("back_to_home")}
          </Button>
        </Content>
      </Layout>
    </>
  );
}

export default NotFound;
