import { get, post, put, del, patch } from "../utils/request";

export const getVouchersList = async () => {
  const result = await get("vouchers?showHidden=true");
  return result;
};

export const addVoucher = async (voucherData) => {
  const result = await post("vouchers", voucherData);
  return result;
};

export const updateVoucher = async (id, voucherData) => {
  const result = await put(`vouchers/${id}`, voucherData);
  return result;
};

export const deleteVoucher = async (id) => {
  const result = await del(`vouchers/${id}`);
  return result;
};

export const updateVoucherStatus = async (id, data) => {
  const result = await patch(`vouchers/${id}/status`, data);
  return result;
};
