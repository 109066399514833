import { get, post, patch, put, del } from "../utils/request";

export const getPostsList = async (page, limit) => {
  const result = await get(`posts/user?page=${page}&limit=${limit}`);
  return result;
};

export const deletePost = async (id) => {
  const result = await del(`posts/${id}`);
  return result;
};

export const togglePublishPost = async (id, data) => {
  const result = await patch(`posts/${id}/publish`, data);
  return result;
};

export const getPostsListByAdmin = async (page, limit) => {
  const result = await get(`posts/admin?page=${page}&limit=${limit}`);
  return result;
};

export const addPost = async (formData) => {
  return await post("posts", formData);
};

export const updatePost = async (id, data, token) => {
  const result = await put(`posts/${id}`, data, token);
  return result;
};

export const getPostById = async (id) => {
  const response = await get(`posts/${id}`);
  return response;
};

export const getUserInfo = async (token) => {
  const result = await get("admin/me", token);
  return result;
};

export const updateApprovalStatus = async (id, data, token) => {
  const result = await patch(`posts/${id}/approval-status`, data, token);
  return result;
};
