import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { addPost } from "../../../api/post.api";
import { useNavigate } from "react-router-dom";
import { MyUploadAdapterPlugin } from "../../../utils/MyUploadAdapter";
import { useAdmin } from "../../../contexts/getAdminInfo";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";    
const { Dragger } = Upload;

const AddPost = () => {
  const { t } = useTranslation("addPost");
  const [form] = Form.useForm();
  const [content, setContent] = useState("");
  const { adminInfo } = useAdmin();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const handleUploadChange = ({ fileList }) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      setSelectedFile(null);
      setImagePreview(null);
    }
  };

  const onFinish = async (values) => {
    if (!selectedFile) {
      message.error("Vui lòng chọn một ảnh!");
      return;
    }

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", content);
    formData.append("admin", adminInfo._id);
    formData.append("thumbnail", selectedFile);

    setLoading(true);

    try {
      const result = await addPost(formData);

      if (result.success) {
        message.success("Bài viết đã được thêm thành công!");
        form.resetFields();
        setContent("");
        setSelectedFile(null);
        setImagePreview(null);
        navigate("/post-admin-list");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      const isJPGorPNG =
        file.type === "image/jpeg" || file.type === "image/png";

      if (!isJPGorPNG) {
        message.error(t("invalid_image_format"));
        return false;
      }

      return true;
    },
    onChange: (info) => {
      const validFileList = info.fileList.filter((file) => {
        const isJPGorPNG =
          file.type === "image/jpeg" || file.type === "image/png";
        return isJPGorPNG;
      });

      if (validFileList.length === 0) {
        setSelectedFile(null);
        setImagePreview(null);
      } else {
        const file = validFileList[0].originFileObj || validFileList[0];
        setSelectedFile(file);
        setImagePreview(URL.createObjectURL(file));
      }
    },
    showUploadList: false,
  };

  return (
    <>
      <Helmet>
        <title>{t("themBaiViet")}</title>
      </Helmet>
      <h2 className="mb-20">{t("themBaiViet")}</h2>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="add-book-section">
          <h3 className="add-post-title">{t("chonAnhBaiViet")}</h3>

          {imagePreview ? (
            <div className="image-preview">
              <h4>{t("anhXemTruoc")}: </h4>
              <img src={imagePreview} alt="Preview" />
              <DeleteOutlined
                onClick={() => {
                  setSelectedFile(null);
                  setImagePreview(null);
                }}
              />
            </div>
          ) : (
            <Form.Item
              name="thumbnail"
              valuePropName="fileList" // Use fileList instead of value
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
              rules={[{ required: true, message: t("vuiLongChonAnh") }]}
            >
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("upload_instruction")}</p>
              </Dragger>
            </Form.Item>
          )}
        </div>

        <Form.Item
          label={t("tieuDe")}
          name="title"
          rules={[{ required: true, message: t("vuiLongNhapTieuDe") }]}
        >
          <Input placeholder={t("vuiLongNhapTieuDe")} />
        </Form.Item>

        <Form.Item
          label={t("noiDung")}
          name="content"
          rules={[{ required: true, message: t("vuiLongNhapNoiDung") }]}
        >
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => setContent(editor.getData())}
            config={{ extraPlugins: [MyUploadAdapterPlugin] }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t("dangBaiViet")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default AddPost;
