import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import dashboardEn from "../locales/en/dashboard.json";
import dashboardVi from "../locales/vi/dashboard.json";
import accountManagerEn from "../locales/en/accountManager.json";
import accountManagerVi from "../locales/vi/accountManager.json";
import adminManagerEn from "../locales/en/adminManager.json";
import adminManagerVi from "../locales/vi/adminManager.json";
import ageGroupEn from "../locales/en/ageGroup.json";
import ageGroupVi from "../locales/vi/ageGroup.json";
import authorEn from "../locales/en/author.json";
import authorVi from "../locales/vi/author.json";
import addBookEn from "../locales/en/addBook.json";
import addBookVi from "../locales/vi/addBook.json";
import bookDetailEn from "../locales/en/bookDetail.json";
import bookDetailVi from "../locales/vi/bookDetail.json";
import bookListEn from "../locales/en/bookList.json";
import bookListVi from "../locales/vi/bookList.json";
import editBookEn from "../locales/en/editBook.json";
import editBookVi from "../locales/vi/editBook.json";
import trashBookEn from "../locales/en/trashBook.json";
import trashBookVi from "../locales/vi/trashBook.json";
import orderListEn from "../locales/en/orderList.json";
import orderListVi from "../locales/vi/orderList.json";
import categoryManagerEn from "../locales/en/categoryManager.json";
import categoryManagerVi from "../locales/vi/categoryManager.json";
import notFoundEn from "../locales/en/notFound.json";
import notFoundVi from "../locales/vi/notFound.json";
import billEn from "../locales/en/bill.json";
import billVi from "../locales/vi/bill.json";
import orderDetailEn from "../locales/en/orderDetail.json"
import orderDetailVi from "../locales/vi/orderDetail.json"
import addPostEn from "../locales/en/addPost.json"
import addPostVi from "../locales/vi/addPost.json"
import editAdminPostEn from "../locales/en/editAdminPost.json";
import editAdminPostVi from "../locales/vi/editAdminPost.json";
import postAdminDetailEn from "../locales/en/postAdminDetail.json";
import postAdminDetailVi from "../locales/vi/postAdminDetail.json";
import postAdminListEn from "../locales/en/postAdminList.json";
import postAdminListVi from "../locales/vi/postAdminList.json";
import postDetailEn from "../locales/en/postDetail.json";
import postDetailVi from "../locales/vi/postDetail.json";
import postListEn from "../locales/en/postList.json";
import postListVi from "../locales/vi/postList.json";
import pubListherManagerEn from "../locales/en/pubLisherManager.json";
import pubListherManagerVi from "../locales/vi/pubLisherManager.json";
import shippingFeesEn from "../locales/en/shippingFees.json";
import shippingFeesVi from "../locales/vi/shippingFees.json";
import transactionManagerEn from "../locales/en/transactionManager.json";
import transactionManagerVi from "../locales/vi/transactionManager.json";
import voucherListEn from "../locales/en/voucherList.json";
import voucherListVi from "../locales/vi/voucherList.json";
import menuSiderEn from "../locales/en/menuSider.json";
import menuSiderVi from "../locales/vi/menuSider.json";
import bannerManagerEn from "../locales/en/bannerManager.json";
import bannerManagerVi from "../locales/vi/bannerManager.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      dashboard: dashboardEn,
      accountManager: accountManagerEn,
      adminManager: adminManagerEn,
      ageGroup: ageGroupEn,
      author: authorEn,
      addBook: addBookEn,
      bookDetail: bookDetailEn,
      bookList: bookListEn,
      editBook: editBookEn,
      trashBook: trashBookEn,
      orderList: orderListEn,
      categoryManager: categoryManagerEn,
      notFound: notFoundEn,
      bill: billEn,
      orderDetail: orderDetailEn,
      addPost: addPostEn,
      editAdminPost: editAdminPostEn,
      postAdminDetail: postAdminDetailEn,
      postAdminList: postAdminListEn,
      postDetail: postDetailEn,
      postList: postListEn,
      pubListherManager: pubListherManagerEn,
      shippingFees: shippingFeesEn,
      transactionManager: transactionManagerEn,
      voucherList: voucherListEn,
      menuSider: menuSiderEn,
      bannerManager: bannerManagerEn,
    },
    vi: {
      dashboard: dashboardVi,
      accountManager: accountManagerVi,
      adminManager: adminManagerVi,
      ageGroup: ageGroupVi,
      author: authorVi,
      addBook: addBookVi,
      bookDetail: bookDetailVi,
      bookList: bookListVi,
      editBook: editBookVi,
      trashBook: trashBookVi,
      orderList: orderListVi,
      categoryManager: categoryManagerVi,
      notFound: notFoundVi,
      bill: billVi,
      orderDetail: orderDetailVi,
      addPost: addPostVi,
      editAdminPost: editAdminPostVi,
      postAdminDetail: postAdminDetailVi,
      postAdminList: postAdminListVi,
      postDetail: postDetailVi,
      postList: postListVi,
      pubListherManager: pubListherManagerVi,
      shippingFees: shippingFeesVi,
      transactionManager: transactionManagerVi,
      voucherList: voucherListVi,
      menuSider: menuSiderVi,
      bannerManager: bannerManagerVi,
    },
  },
  lng: "vi", 
  fallbackLng: "en", 
  ns: [
    "dashboard",
    "accountManager",
    "adminManager",
    "ageGroup",
    "author",
    "addBook",
    "bookDetail",
    "bookList",
    "editBook",
    "trashBook",
    "orderList",
    "categoryManager",
    "notFound",
    "bill",
    "orderDetail",
    "addPost",
    "editAdminPost",
    "postAdminDetail",
    "postAdminList",
    "postDetail",
    "postList",
    "pubListherManager",
    "shippingFees",
    "transactionManager",
    "voucherList",
    "menuSider",
    "bannerManager",
  ],
  lng: localStorage.getItem("language") || "vi", 
  fallbackLng: "en",
  defaultNS: "dashboard", 
  interpolation: {
    escapeValue: false, 
  },
});

export default i18n;
