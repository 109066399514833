import { get, post, put, del } from "../utils/request";

export const login = async (options) => {
  const result = await post("admin/login", options);
  return result;
};

export const registerAdmin = async (adminData) => {
  const result = await post("admin/register", adminData);
  return result;
};

export const changePassword = async (adminData, token) => {
  const result = await put("admin/change-password", adminData, token);
  return result;
};

export const deleteAdmin = async (adminId) => {
  const result = await del(`admin/${adminId}`);
  return result;
};

export const getAdminInfo = async (token) => {
  const result = await get("admin/me", token);
  return result;
};

export const getAccountAdmin = async () => {
  const result = await get("admin/all");
  return result;
};
