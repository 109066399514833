import { get, patch, postMulti, patchMulti, patchImg, delImg } from "../utils/request";

export const getBanner = async (position) => {
  const result = await get(`banner?position=${position}&showHidden=true`);
  return result;
};

export const createBanner = async () => {
  const result = await postMulti("banner");
  return result;
};

export const addMoreImg = async (bannerId, images) => {
  const result = await patchMulti(`banner/${bannerId}/images`, images);
  return result;
};

export const updateImage = async (bannerId, option) => {
  const result = await patchImg(`banner/${bannerId}/image`, option);
  return result;
};

export const updateStatus = async (bannerId, option) => {
  const result = await patch(`banner/${bannerId}/image-status`, option);
  return result;
};

export const deleteImage = async (bannerId, data) => {
  const result = await delImg(`banner/${bannerId}/image`, data);
  return result;
};