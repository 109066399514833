export const formattedDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return (
    <span>{`${day}/${month}/${year}`}</span>
  );
};
