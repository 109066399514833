import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Spin,
  Typography,
  Col,
  Row,
  Button,
  Modal,
  Tag,
  message,
  Popconfirm,
} from "antd";
import { getPostById, updateApprovalStatus } from "../../../api/post.api";
import { getComments, delComment } from "../../../api/comment.api";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Title, Paragraph } = Typography;

const PostDetail = () => {
  const { t } = useTranslation("postDetail");
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [commentsData, setCommentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showReplies, setShowReplies] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newApprovalStatus, setNewApprovalStatus] = useState("");

  useEffect(() => {
    const fetchPostDetail = async () => {
      try {
        const response = await getPostById(id);
        setPost(response.data);

        const commentsResponse = await getComments(id);
        setCommentsData(commentsResponse.data);
      } catch (error) {
        console.error("Error fetching post or comments:", error);
        message.error(t("fetchError"));
      }
    };

    fetchPostDetail();
  }, [id, t]);

  const fetchCommentsData = async () => {
    try {
      const commentsResponse = await getComments(id);
      setCommentsData(commentsResponse.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
      message.error(t("fetchCommentsError"));
    }
  };

  const toggleReplies = (commentId) => {
    setShowReplies((prevShowReplies) => ({
      ...prevShowReplies,
      [commentId]: !prevShowReplies[commentId],
    }));
  };

  const showApprovalModal = (status) => {
    setNewApprovalStatus(status);
    setIsModalOpen(true);
  };

  const handleApprovalStatusChange = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token không tồn tại");
      message.error(t("tokenError"));
      return;
    }

    try {
      await updateApprovalStatus(
        id,
        { approval_status: newApprovalStatus },
        token
      );
      setPost((prevPost) => ({
        ...prevPost,
        approval_status: newApprovalStatus,
      }));
      message.success(t("statusUpdateSuccess", { status: newApprovalStatus }));
    } catch (error) {
      console.error(
        "Error updating approval status:",
        error.response ? error.response.data : error.message
      );
      message.error(t("statusUpdateError"));
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleDeleteComment = async (id) => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        message.warning(t("loginRequired"));
        return;
      }

      await delComment(id, token);
      await fetchCommentsData();

      message.success(t("commentDeleteSuccess"));
    } catch (error) {
      console.error("Lỗi khi xóa bình luận:", error);
      message.error(t("commentDeleteError", { error: error.message }));
    } finally {
      setIsLoading(false);
    }
  };

  const renderStatusIcon = (status) => {
    if (status === "Đã duyệt") {
      return (
        <span style={{ color: 'green', display: "flex", alignItems: "center" }}>
          <CheckCircleOutlined style={{ color: 'green', fontSize: 22, marginRight: "5px" }} />
          {t("confirmApprovalModal.title", {status})}
        </span>
      );
    }
    if (status === "Từ chối duyệt") {
      return (
        <span style={{ color: 'red', display: "flex", alignItems: "center" }}>
          <CloseCircleOutlined style={{ color: 'red', fontSize: 22, marginRight: "5px" }} />
          {t("confirmApprovalModal.title2", {status})}
        </span>
      );
    }
    return null; 
  };

  if (!post) {
    return (
      <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <div
        style={{
          padding: "40px",
          maxWidth: "1200px",
          margin: "0 auto",
          backgroundColor: "#f7f7f7",
          borderRadius: "8px",
        }}
      >
        <Card
          bordered={false}
          style={{
            marginBottom: "20px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <img
                src={post.thumbnail}
                alt="Thumbnail"
                style={{
                  width: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Col>
            <Col span={16}>
              <Title level={2} style={{ margin: "20px 0", color: "#333" }}>
                {post.title}
              </Title>
              <Paragraph style={{ margin: "10px 0" }}>
                <strong>{t("author")}</strong>{" "}
                {post.user ? post.user.full_name : t("unknownUser")}
              </Paragraph>
              <Paragraph style={{ margin: "0" }}>
                <strong>{t("views")}</strong> {post.views}
              </Paragraph>
              <Paragraph style={{ margin: "10px 0" }}>
                <strong>{t("approvalStatus")}</strong>{" "}
                <Tag
                  color={
                    post.approval_status === "Đã duyệt"
                      ? "green"
                      : post.approval_status === "Chờ duyệt"
                        ? "orange"
                        : post.approval_status === "Từ chối duyệt"
                          ? "red"
                          : "default"
                  }
                >
                  {post.approval_status === "Đã duyệt"
                    ? t("approve")
                    : post.approval_status === "Chờ duyệt"
                      ? t("confirmApproval")
                      : post.approval_status === "Từ chối duyệt"
                        ? t("reject")
                        : t("unknown")}
                </Tag>
              </Paragraph>
              <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
                {post.approval_status === "Đã duyệt" ? (
                  <></>
                ) : (
                  <>
                    <Button
                      type="primary"
                      onClick={() => showApprovalModal("Đã duyệt")}
                    >
                      {t("approve2")}
                    </Button>
                  </>
                )}
                <Button
                  type="primary"
                  onClick={() => showApprovalModal("Từ chối duyệt")}
                  danger
                >
                  {t("reject")}
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <Modal
          title={renderStatusIcon(newApprovalStatus)}
          open={isModalOpen}
          onOk={handleApprovalStatusChange}
          onCancel={() => setIsModalOpen(false)}
          okText={t("confirm")}
          cancelText={t("cancel")}
        >
          <p>{t("confirmApprovalModal.message", { status: newApprovalStatus })}</p>
        </Modal>

        <Card
          bordered={false}
          style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
        >
          <Title level={4}>{t("content")}</Title>
          <p dangerouslySetInnerHTML={{ __html: post.content }} />
        </Card>

        <Card
          bordered={false}
          style={{
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            marginTop: "20px",
            borderRadius: "8px",
          }}
        >
          <Title level={4}>{t("comments")}</Title>
          {commentsData.length > 0 ? (
            commentsData.map((comment) => (
              <Card
                key={comment._id}
                style={{
                  marginBottom: "10px",
                  borderRadius: "5px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <img
                      alt={comment.user.full_name}
                      src={comment.user.avatar}
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "10px",
                        objectFit: "cover",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Paragraph
                        style={{
                          margin: "0",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {comment.user.full_name}
                      </Paragraph>
                      <Paragraph style={{ margin: "0", fontSize: "15px" }}>
                        {comment.content}
                      </Paragraph>
                    </div>
                  </div>
                  <small
                    className="text-muted"
                    style={{ alignSelf: "flex-start", fontSize: "14px" }}
                  >
                    {new Date(comment.createdAt).toLocaleString()}
                  </small>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                    marginLeft: "60px",
                  }}
                >
                  {comment.replies.length > 0 && (
                    <span
                      className="comment-reply text-primary ms-3"
                      onClick={() => toggleReplies(comment._id)}
                      style={{
                        cursor: "pointer",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    >
                      {showReplies[comment._id]
                        ? t("hideReplies")
                        : `${t("viewReplies")} (${comment.replies.length})`}
                    </span>
                  )}
                  <div style={{ textAlign: "left" }}>
                    <Popconfirm
                      title={t("confirmDeleteComment")}
                      onConfirm={() => handleDeleteComment(comment._id)}
                      okText={t("delete")}
                      cancelText={t("cancel")}
                    >
                      <button
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "red",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                      >
                        {t("deleteComment")}
                      </button>
                    </Popconfirm>
                  </div>
                </div>

                {showReplies[comment._id] && comment.replies.length > 0 && (
                  <div style={{ marginLeft: "40px", marginTop: "10px" }}>
                    {comment.replies.map((reply) => (
                      <div
                        key={reply._id}
                        style={{
                          marginBottom: "10px",

                          alignItems: "flex-start",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img
                            alt={reply.user.full_name}
                            src={
                              reply.user.avatar ||
                              "https://res.cloudinary.com/drotlopmt/image/upload/v1730101139/avatar_kjtiju.png"
                            }
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "15px",
                              marginRight: "10px",
                              alignItems: "center",
                            }}
                          />
                          <div>
                            <Paragraph
                              style={{
                                margin: "0",
                                fontWeight: "bold",
                                fontSize: "16px",
                              }}
                            >
                              {reply.user.full_name}
                            </Paragraph>
                            <Paragraph
                              style={{ margin: "0", fontSize: "14px" }}
                            >
                              {reply.content}
                            </Paragraph>
                          </div>

                          <small
                            className="text-muted"
                            style={{
                              fontSize: "12px",
                              marginLeft: "auto",
                            }}
                          >
                            {new Date(reply.createdAt).toLocaleString()}
                          </small>
                        </div>

                        <div style={{ textAlign: "left", marginTop: "5px" }}>
                          <Popconfirm
                            title={t("confirmDeleteComment")}
                            onConfirm={() => handleDeleteComment(reply._id)}
                            okText={t("yes")}
                            cancelText={t("cancel")}
                          >
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "red",
                                cursor: "pointer",
                                fontSize: "14px",
                                marginLeft: "45px",
                              }}
                            >
                              {t("deleteComment")}
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Card>
            ))
          ) : (
            <Paragraph>{t("noComments")}</Paragraph>
          )}
        </Card>
      </div>
    </>
  );
};

export default PostDetail;
