import { get, patch, del } from '../utils/request';

export const fetchReviews = async (bookId) => {
  const response = await get(`reviews?bookId=${bookId}&showHidden=true`);
  return response.data;
};

export const toggleReviewVisibility = async (reviewId, currentVisibility) => {
  const response = await patch(`reviews/${reviewId}/is-hidden`, { is_hidden: !currentVisibility });
  return response.data;
};

export const deleteReview = async (reviewId) => {
  const response = await del(`reviews/${reviewId}`);
  return response.data;
};
