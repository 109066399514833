import { useState, useEffect } from "react";
import {
  Table,
  Card,
  Typography,
  Tabs,
  Button,
  Switch,
  Modal,
  Upload,
  message,
  Image,
  Spin,
  Popconfirm,
} from "antd";
import { UploadOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  getBanner,
  addMoreImg,
  updateImage,
  updateStatus,
  deleteImage,
} from "../../api/banner.api";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { Title } = Typography;
const { TabPane } = Tabs;

const BannerManagement = () => {
  const { t } = useTranslation("bannerManager");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [mainBanner, setMainBanner] = useState([]);
  const [secondaryBanner, setSecondaryBanner] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);
  const [bottomBanner, setBottomBanner] = useState([]);
  const [blogBanner, setBlogBanner] = useState([]);
  const [aboutUsBanner, setAboutUsBanner] = useState([]);

  const [currentBanner, setCurrentBanner] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newImageFile, setNewImageFile] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const fetchBanners = async () => {
    try {
      setLoading(true);

      const banners = await Promise.all([
        getBanner("main_banner"),
        getBanner("secondary_banner"),
        getBanner("category_banner"),
        getBanner("bottom_banner"),
        getBanner("blog_banner"),
        getBanner("about-us_banner"),
      ]);

      const flattenBannerData = (data) =>
        data.map((item) => ({
          parentId: item._id,
          position: item.position,
          title: item.title,
          images: item.images.map((img) => ({
            ...img,
            parentId: item._id,
          })),
        }));

      setMainBanner(flattenBannerData(banners[0].data));
      setSecondaryBanner(flattenBannerData(banners[1].data));
      setCategoryBanner(flattenBannerData(banners[2].data));
      setBottomBanner(flattenBannerData(banners[3].data));
      setBlogBanner(flattenBannerData(banners[4].data));
      setAboutUsBanner(flattenBannerData(banners[5].data));
    } catch (error) {
      message.error(t("errorLoading"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const handleAddBanner = (bannerId) => {
    setAddModalVisible(true);
    setCurrentBanner({ id: bannerId });
  };

  const handleAddImage = async () => {
    try {
      setActionLoading(true);
      if (!currentBanner?.id || selectedImages.length === 0) {
        message.warning(t("chooseAtLeastOneImage"));
        return;
      }

      const formData = new FormData();
      selectedImages.forEach((file) => {
        formData.append("images", file);
      });

      await addMoreImg(currentBanner.id, formData);

      message.success(t("addSuccess"));
      setAddModalVisible(false);
      setSelectedImages([]);

      fetchBanners();
    } catch (error) {
      message.error(
        "Lỗi khi thêm ảnh: " + (error.response?.data?.error || error.message)
      );
    } finally {
      setActionLoading(false);
    }
  };

  const handleUpdateBanner = async () => {
    try {
      setActionLoading(true);
      if (!currentBanner || !newImageFile) {
        message.warning(t("pleaseChooseNewImage"));
        return;
      }

      const formData = new FormData();
      formData.append("oldImageUrl", currentBanner.url);
      formData.append("image", newImageFile);

      await updateImage(currentBanner.parentId, formData);

      message.success(t("updateSuccess"));
      setIsModalVisible(false);
      setNewImageFile(null);

      fetchBanners();
    } catch (error) {
      console.error("Lỗi khi cập nhật:", error);
      message.error(
        "Lỗi khi cập nhật: " + (error.response?.data?.error || error.message)
      );
    } finally {
      setActionLoading(false);
    }
  };

  const handleVisibilityChange = async (bannerId, imageUrl, status) => {
    try {
      await updateStatus(bannerId, { imageUrl, status });
      message.success(t("statusUpdateSuccess"));
      fetchBanners();
    } catch (error) {
      message.error("Lỗi khi cập nhật trạng thái!");
    }
  };

  const handleDeleteImage = async (bannerId, imageUrl) => {
    try {
      setActionLoading(true);
      await deleteImage(bannerId, { imageUrl });
      message.success(t("deleteSuccess"));

      fetchBanners();
    } catch (error) {
      message.error(t("deleteError"));
    } finally {
      setActionLoading(false);
    }
  };

  const columns = [
    {
      title: t("image"),
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <Image
          src={url}
          alt="banner"
          style={{
            width: 250,
            height: 125,
            objectFit: "cover",
            borderRadius: 5,
          }}
        />
      ),
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status, record) => (
        <Switch
          checked={status}
          checkedChildren={t("visible")}
          unCheckedChildren={t("hidden")}
          onChange={(checked) =>
            handleVisibilityChange(record.parentId, record.url, checked)
          }
        />
      ),
    },
    {
      title: t("actions"),
      key: "actions",
      align: "center",
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setCurrentBanner(record);
              setNewImageFile(null);
              setIsModalVisible(true);
            }}
            style={{ marginRight: "5px" }}
          >
            {t("update")}
          </Button>
          <Popconfirm
            title={t("areYouSure")}
            description={t("deleteImage")}
            okText={t("yes")}
            cancelText={t("no")}
            onConfirm={() => handleDeleteImage(record.parentId, record.url)}
            okButtonProps={{ danger: true }}
          >
            <Button danger>{t("delete")}</Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("manageBanner")}</title>
      </Helmet>
      <Spin spinning={loading}>
        <Title level={3}>{t("manageBanner")}</Title>
        <Tabs
          defaultActiveKey="main"
          tabBarStyle={{ marginBottom: 20 }}
          items={[
            {
              label: t("homePage"),
              key: "main",
              children: (
                <>
                  <Card
                    title={t("mainBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAddBanner(mainBanner[0]?.parentId)}
                      >
                        {t("addMainBannerImage")}
                      </Button>
                    }
                  >
                    <Spin spinning={loading}>
                      <Table
                        loading={loading}
                        dataSource={mainBanner.flatMap((item) => item.images)}
                        columns={columns}
                        rowKey="_id"
                        pagination={{
                          pageSize: 3,
                          defaultCurrent: 1,
                          total: mainBanner.flatMap((item) => item.images)
                            .length,
                        }}
                      />
                    </Spin>
                  </Card>

                  <Card
                    title={t("secondaryBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                          handleAddBanner(secondaryBanner[0]?.parentId)
                        }
                      >
                        {t("addSecondaryBanner")}
                      </Button>
                    }
                    style={{ marginBottom: 20 }}
                  >
                    <Table
                      loading={loading}
                      dataSource={secondaryBanner.flatMap(
                        (item) => item.images
                      )}
                      columns={columns}
                      rowKey="_id"
                      pagination={{
                        pageSize: 3,
                        defaultCurrent: 1,
                        total: secondaryBanner.flatMap((item) => item.images)
                          .length,
                      }}
                    />
                  </Card>

                  <Card
                    title={t("categoryBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                          handleAddBanner(categoryBanner[0]?.parentId)
                        }
                      >
                        {t("addCategoryBannerImage")}
                      </Button>
                    }
                    style={{ marginBottom: 20 }}
                  >
                    <Table
                      loading={loading}
                      dataSource={categoryBanner.flatMap((item) => item.images)}
                      columns={columns}
                      rowKey="_id"
                      pagination={{
                        pageSize: 3,
                        defaultCurrent: 1,
                        total: categoryBanner.flatMap((item) => item.images)
                          .length,
                      }}
                    />
                  </Card>

                  <Card
                    title={t("bottomBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                          handleAddBanner(bottomBanner[0]?.parentId)
                        }
                      >
                        {t("addBottomBannerImage")}
                      </Button>
                    }
                  >
                    <Table
                      loading={loading}
                      dataSource={bottomBanner.flatMap((item) => item.images)}
                      columns={columns}
                      rowKey="_id"
                      pagination={{
                        pageSize: 3,
                        defaultCurrent: 1,
                        total: bottomBanner.flatMap((item) => item.images)
                          .length,
                      }}
                    />
                  </Card>
                </>
              ),
            },
            {
              label: t("newsPage"),
              key: "category",
              children: (
                <>
                  <Card
                    title={t("newsBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAddBanner(blogBanner[0]?.parentId)}
                      >
                        {t("addNewsBannerImage")}
                      </Button>
                    }
                  >
                    <Spin spinning={loading}>
                      <Table
                        loading={loading}
                        dataSource={blogBanner.flatMap((item) => item.images)}
                        columns={columns}
                        rowKey="_id"
                        pagination={{
                          pageSize: 3,
                          defaultCurrent: 1,
                          total: blogBanner.flatMap((item) => item.images)
                            .length,
                        }}
                      />
                    </Spin>
                  </Card>
                </>
              ),
            },
            {
              label: t("aboutUsPage"),
              key: "footer",
              children: (
                <>
                  <Card
                    title={t("aboutUsBanner")}
                    extra={
                      <Button
                        type="dashed"
                        icon={<PlusCircleOutlined />}
                        onClick={() =>
                          handleAddBanner(aboutUsBanner[0]?.parentId)
                        }
                      >
                        {t("addAboutUsBannerImage")}
                      </Button>
                    }
                  >
                    <Spin spinning={loading}>
                      <Table
                        loading={loading}
                        dataSource={aboutUsBanner.flatMap(
                          (item) => item.images
                        )}
                        columns={columns}
                        rowKey="_id"
                        pagination={{
                          pageSize: 3,
                          defaultCurrent: 1,
                          total: aboutUsBanner.flatMap((item) => item.images)
                            .length,
                        }}
                      />
                    </Spin>
                  </Card>
                </>
              ),
            },
          ]}
        />

        <Modal
          title={t("updateImage")}
          open={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
            setNewImageFile(null);
          }}
          onOk={handleUpdateBanner}
          confirmLoading={actionLoading}
          cancelText={t("no")} 
          okText={t("yes")}  
        >
          {currentBanner && (
            <Spin spinning={actionLoading}>
              <Image
                src={
                  newImageFile
                    ? URL.createObjectURL(newImageFile)
                    : currentBanner.url
                }
                alt={t("currentImage")}
                style={{
                  objectFit: "cover",
                }}
              />

              <Upload.Dragger
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  const validImageTypes = [
                    "image/png",
                    "image/jpeg",
                    "image/jpg",
                  ];
                  const isValidType = validImageTypes.includes(file.type);

                  if (!isValidType) {
                    message.error("Only PNG or JPG images are allowed.");
                    return;
                  }

                  setTimeout(() => {
                    onSuccess("ok");
                    setNewImageFile(file);
                  }, 0);
                }}
              >
                <Button icon={<UploadOutlined />}>Upload New Image</Button>
              </Upload.Dragger>
            </Spin>
          )}
        </Modal>

        <Modal
          title={t("addImages")}
          open={addModalVisible}
          onCancel={() => {
            setAddModalVisible(false);
            setSelectedImages([]);
          }}
          onOk={handleAddImage}
          confirmLoading={actionLoading}
          cancelText={t("no")} 
          okText={t("yes")}  
        >
          <Upload.Dragger
            multiple
            showUploadList={false}
            customRequest={({ file, onSuccess }) => {
              const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
              const isValidType = validImageTypes.includes(file.type);

              if (!isValidType) {
                message.error(t("invalidFileType"));
                return;
              }

              setTimeout(() => {
                onSuccess("ok");
                setSelectedImages((prev) => [...prev, file]);
              }, 0);
            }}
          >
            <Button icon={<UploadOutlined />}>{t("selectImages")}</Button>
          </Upload.Dragger>

          {selectedImages.length > 0 && (
            <div
              style={{
                marginTop: 20,
                display: "flex",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              {selectedImages.map((file, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <Image
                    src={URL.createObjectURL(file)}
                    alt={`ảnh ${index + 1}`}
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                </div>
              ))}
            </div>
          )}
        </Modal>
      </Spin>
    </>
  );
};

export default BannerManagement;
