import React from "react";
import "./App.css";
import AllRouter from "./components/AllRouter";
import { AdminProvider } from "./contexts/getAdminInfo";
import "./i18n/i18n"; // Import file cấu hình i18n

function App() {
  return (
    <AdminProvider>
      <AllRouter />
    </AdminProvider>
  );
}

export default App;
