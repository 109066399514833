import { useState, useEffect } from "react";
import { Table, message, Button, Dropdown, Modal } from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getTemporaryDelete,
  deleteStatus,
  deleteBook,
} from "../../../api/book.api";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const { confirm } = Modal;

const TrashBook = () => {
  const { t } = useTranslation("trashBook");
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const fetchDeletedBooks = async () => {
    setLoading(true);
    try {
      const response = await getTemporaryDelete();
      if (response?.success && Array.isArray(response.data)) {
        setBooks(response.data);
      } else {
        message.error("Không thể tải danh sách sách đã xóa");
      }
    } catch {
      message.error("Lỗi khi tải danh sách sách đã xóa");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeletedBooks();
  }, []);

  const handleRestore = (bookId) => {
    confirm({
      title: t("restore_confirm_title"),
      content: t("restore_confirm_content"),
      okText: t("yes"),
      cancelText: t("no"),
      onOk: async () => {
        try {
          const response = await deleteStatus(bookId);
          if (response.success) {
            message.success(t("restore_success"));
            fetchDeletedBooks();
          }
        } catch {
          message.error(t("restore_error"));
        }
      },
    });
  };

  const handlePermanentDelete = (bookId) => {
    confirm({
      title: t("delete_confirm_title"),
      content: t("delete_confirm_content"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: async () => {
        try {
          const response = await deleteBook(bookId);
          if (response.success) {
            message.success(t("delete_success"));
            fetchDeletedBooks();
          }
        } catch {
          message.error(t("delete_error"));
        }
      },
    });
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedBooks = books.slice(startIndex, startIndex + pageSize);

  return (
    <>
      <Helmet>
        <title>{t("trash_title")}</title>
      </Helmet>
      <h1 style={{ padding: "20px 0px" }}>{t("trash_title")}</h1>

      <Table
        columns={[
          {
            title: t("image"),
            dataIndex: "images",

            key: "images",
            render: (images) => (
              <img src={images[0]} alt="Book cover" width={50} height={50} style={{objectFit: "cover"}}/>
            ),
          },
          {
            title: t("book_title"),
            dataIndex: "title",
            key: "title",
            align: "center",
            width: 300,
          },
          {
            title: t("quantity"),
            dataIndex: "stock",
            key: "stock",
            align: "center",
          },
          {
            title: t("author"),
            dataIndex: ["author", "name"],
            key: "author",
            align: "center",
          },
          {
            title: t("actions"),
            align: "center",
            key: "actions",
            render: (_, record) => {
              const menuItems = [
                {
                  key: "restore",
                  label: t("restore"),
                  icon: <UndoOutlined />,
                  onClick: () => handleRestore(record._id),
                },
                {
                  key: "delete",
                  label: t("delete_permanent"),
                  icon: <DeleteOutlined />,
                  onClick: () => handlePermanentDelete(record._id),
                },
              ];
              return (
                <Dropdown menu={{ items: menuItems }}>
                  <Button
                    icon={<EllipsisOutlined />}
                    onClick={(e) => e.preventDefault()}
                  />
                </Dropdown>
              );
            },
          },
        ]}
        dataSource={paginatedBooks}
        rowKey="_id"
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: books.length,
          onChange: setCurrentPage,
          showTotal: (total) => `${t("total_books")} ${total}`,
        }}
      />
    </>
  );
};

export default TrashBook;
