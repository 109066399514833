import { get } from "../utils/request";

export const getTransactions = async () => {
  const response = await get("payment/transactions");
  return response?.data || [];
};

export const getTransactionByRef = async (txnRef) => {
  const response = await get(`payment/transactions/${txnRef}`);
  return response?.data || null;
};