import { get, post, put, del } from "../utils/request";

export const getAuthorsList = async () => {
  const result = await get("authors");
  return result;
};

export const addAuthor = async (authorData) => {
  const result = await post("authors", authorData);
  return result;
};

export const updateAuthor = async (id, authorData) => {
  const result = await put(`authors/${id}`, authorData);
  return result;
};

export const deleteAuthor = async (id) => {
  const result = await del(`authors/${id}`);
  return result;
};