import { useEffect, useState } from "react";
import { Table, Button, Typography, Modal, Input, message, Image, Dropdown, Menu, Tag, Select } from "antd";
import { DeleteOutlined, EllipsisOutlined } from '@ant-design/icons';
import { MdOutlineLock, MdOutlineLockOpen } from "react-icons/md";
import { getUsersList, updateUserStatus, deleteUser } from "../../api/user.api";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./AccountManager.scss";

const AccountManager = () => {
  const { t } = useTranslation("accountManager");
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState("all");
  const [blockedFilter, setBlockedFilter] = useState("all");
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      const response = await getUsersList();
      setData(response.data.reverse());
    };
    fetchData();
  }, []);

  const filteredData = data
    .filter((user) =>
      user.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .filter((user) => {
      if (activeFilter !== "all") {
        return user.is_active === (activeFilter === "active");
      }
      return true;
    })
    .filter((user) => {
      if (blockedFilter !== "all") {
        return user.is_blocked === (blockedFilter === "blocked");
      }
      return true;
    });

  const columns = [
    {
      title: t("avatar"),
      dataIndex: "avatar",
      key: "avatar",
      align: "center",
      width: 85, 
      render: (avatar) => (
        <div className="custom-avatar">
          <Image src={avatar} />
        </div>
      ),
    },
    {
      title: t("full_name"),
      dataIndex: "full_name",
      key: "full_name",
      align: "center",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: t("phone_number"),
      dataIndex: "phone_number",
      key: "phone_number",
      align: "center",
    },
    {
      title: t("address"),
      key: "address",
      align: "center",
      render: (_, record) => (
        <Typography.Text>
          {record.address}, {record.ward}, {record.province}, {record.city}
        </Typography.Text>
      ),
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => new Date(date).toLocaleDateString("vi-VN"),
    },
    {
      title: t("status"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (isActive) => (
        <Tag color={isActive ? "green" : "red"}>
          {isActive ? t("active") : t("inactive")}
        </Tag>
      ),
    },
    {
      title: t("action"),
      key: "action",
      align: "center",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'block',
                label: record.is_blocked ? t("unblock_account") : t("block_account"),
                icon: record.is_blocked ? <MdOutlineLockOpen /> : <MdOutlineLock />,
              },
              {
                key: 'delete',
                label: t("delete"),
                icon: <DeleteOutlined />,
                style: { color: 'red' },
              }
            ],
            onClick: (e) => handleAction(e, record._id, record.is_blocked),
          }}
        >
          <Button icon={<EllipsisOutlined />} />
        </Dropdown>
      ),
    }
  ];

  const handleAction = (e, id, isBlock) => {
    if (e.key === "block" || e.key === "unblock") {
      handleStatusChange(id, isBlock);
    } else if (e.key === "delete") {
      handleDelete(id);
    }
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: t("confirm_delete"),
      content: t("delete_content"),
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        try {
          await deleteUser(id);
          const updatedData = data.filter((user) => user._id !== id);
          setData(updatedData);
          message.success(t("delete_success"));
        } catch (error) {
          message.error(t("delete_error"));
          console.error("Error deleting user:", error);
        }
      },
    });
  };

  const handleStatusChange = async (id, isBlock) => {
    const action = isBlock ? t("confirm_unblock") : t("confirm_block");
    Modal.confirm({
      title: action,
      content: t("status_change_content"),
      okText: t("yes"),
      okType: "primary",
      cancelText: t("no"),
      onOk: async () => {
        try {
          await updateUserStatus(id, !isBlock);
          const updatedData = data.map((user) =>
            user._id === id ? { ...user, is_blocked: !isBlock } : user
          );
          setData(updatedData);
          message.success(t("status_update_success"));
        } catch (error) {
          message.error(t("status_update_error"));
          console.error("Error updating user status:", error);
        }
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{t("account_title")}</title>
      </Helmet>

      <h2 style={{ marginBottom: "20px" }}>{t("user_list")}</h2>

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "20px" }}>
        <Input.Search
          placeholder={t("search_placeholder")}
          allowClear
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: 300 }}
        />

        <div style={{ display: "flex", gap: "10px" }}>
          <Select
            defaultValue="all"
            onChange={(value) => setActiveFilter(value)}
            style={{ width: 150 }}
          >
            <Select.Option value="all">{t("all_status")}</Select.Option>
            <Select.Option value="active">{t("confirmed")}</Select.Option>
            <Select.Option value="inactive">{t("unconfirmed")}</Select.Option>
          </Select>

          <Select
            defaultValue="all"
            onChange={(value) => setBlockedFilter(value)}
            style={{ width: 150 }}
          >
            <Select.Option value="all">{t("all_locked")}</Select.Option>
            <Select.Option value="blocked">{t("blocked")}</Select.Option>
          </Select>
        </div>
      </div>

      <Table
        dataSource={filteredData.slice((currentPage - 1) * pageSize, currentPage * pageSize)}
        columns={columns}
        rowKey="_id"
        pagination={{
          current: currentPage,
          pageSize,
          total: filteredData.length,
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </>
  );
};

export default AccountManager;