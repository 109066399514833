import "./Loading.scss";

function Loading() {
    return (
        <div className="loading-book">
            <div className="book">
                <div className="inner">
                    <div className="left"></div>
                    <div className="middle"></div>
                    <div className="right"></div>
                </div>
                <ul>
                    {Array.from({ length: 11 }).map((_, index) => (
                        <li key={index}></li>
                    ))}
                </ul>
            </div>

            <img src="https://res.cloudinary.com/drotlopmt/image/upload/v1730467017/%E1%BA%A2nh_ch%E1%BB%A5p_m%C3%A0n_h%C3%ACnh_2024-11-01_200253-removebg-preview_iq20wr.png" alt="Dribbble" />
        </div>
    )
};

export default Loading;