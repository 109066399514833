import { useState, useEffect } from "react";
import { Form, Input, Button, Upload, message, Image } from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useParams, useNavigate } from "react-router-dom";
import { getPostById, updatePost } from "../../../api/post.api";
import { MyUploadAdapterPlugin } from "../../../utils/MyUploadAdapter";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "./EditAdminPost.scss";

const { Dragger } = Upload;

const EditAdminPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [content, setContent] = useState("");
  const [adminId, setAdminId] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [previousThumbnail, setPreviousThumbnail] = useState("");
  const { t } = useTranslation("editAdminPost");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = parseJwt(token);
      if (decoded && decoded.id) {
        setAdminId(decoded.id);
      }
    } else {
      message.error("Không tìm thấy token. Vui lòng đăng nhập lại.");
    }

    const fetchPostData = async () => {
      try {
        const response = await getPostById(id);
        const postData = response.data;

        if (postData) {
          form.setFieldsValue({ title: postData.title });
          setContent(postData.content || "");
          setThumbnailUrl(postData.thumbnail || "");
        }
      } catch (error) {
        message.error("Đã xảy ra lỗi khi tải dữ liệu bài viết!");
      } finally {
        setLoading(false);
      }
    };

    fetchPostData();
  }, [id, form]);

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(escape(window.atob(base64)));
      return JSON.parse(jsonPayload);
    } catch (error) {
      return null;
    }
  };

  const handleUploadChange = ({ fileList }) => {
    if (fileList && fileList.length > 0) {
      setSelectedFile(fileList[0].originFileObj);
      setPreviousThumbnail(URL.createObjectURL(fileList[0].originFileObj));
    }
  };

  const handleRemoveExistingImage = () => {
    setPreviousThumbnail(thumbnailUrl); // Di chuyển ảnh hiện có lên khu vực ảnh bên trên
    setThumbnailUrl(""); // Xóa ảnh hiện có
  };

  const onFinish = async (values) => {
    if (!adminId || adminId.length !== 24) {
      message.error("Admin ID không hợp lệ hoặc không tồn tại!");
      return;
    }

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("content", content);
    formData.append("admin", adminId);
    if (selectedFile) {
      formData.append("thumbnail", selectedFile);
    }

    const token = localStorage.getItem("token");
    if (!token) {
      message.error("Không tìm thấy token. Vui lòng đăng nhập lại.");
      return;
    }

    try {
      const result = await updatePost(id, formData, token);

      if (result && result.success) {
        message.success("Bài viết đã được cập nhật thành công!");
        form.resetFields();
        setContent("");
        setSelectedFile(null);
        setThumbnailUrl("");
        setPreviousThumbnail("");

        navigate("/post-admin-list");
      } else {
        message.error("Đã xảy ra lỗi khi cập nhật bài viết!");
      }
    } catch (error) {
      message.error("Đã xảy ra lỗi khi cập nhật bài viết!");
    }
  };

  const props = {
    name: "file",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      const isJPGorPNG =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPGorPNG) {
        message.error(t("invalid_image_format"));
        return false;
      }
      return true;
    },
    onChange: (info) => {
      const validFileList = info.fileList.filter((file) => {
        const isJPGorPNG =
          file.type === "image/jpeg" || file.type === "image/png";
        return isJPGorPNG;
      });

      if (validFileList.length > 0) {
        const file = validFileList[0].originFileObj || validFileList[0];
        setSelectedFile(file);
        setPreviousThumbnail(URL.createObjectURL(file));
      } else {
        setSelectedFile(null);
      }
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("editPost")}</title>
      </Helmet>
      <h2>{t("editPost")}</h2>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="edit-post-section">
          <h3 className="edit-post-title">{t("choosePostImage")}</h3>

          {thumbnailUrl ? (
            <div className="thumbnail-container">
              <Image
                width={200}
                src={thumbnailUrl}
                className="thumbnail-image"
              />
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                className="delete-button"
                onClick={handleRemoveExistingImage}
              />
            </div>
          ) : (
            <Form.Item name="thumbnail">
              <Dragger {...props} className="upload-dragger">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("choosePostImage")}</p>
              </Dragger>
            </Form.Item>
          )}

          {previousThumbnail && (
            <div className="existing-thumbnail-section">
              <h4 className="existing-thumbnail-title">
                {t("existingImage")}:
              </h4>
              <div className="existing-thumbnail-container">
                <Image
                  width={200}
                  src={previousThumbnail}
                  className="existing-thumbnail"
                />
              </div>
            </div>
          )}
        </div>

        <Form.Item
          label={t("title")}
          name="title"
          rules={[{ required: true, message: t("pleaseEnterTitle") }]}
        >
          <Input placeholder={t("title")} />
        </Form.Item>

        <Form.Item
          label={t("content")}
          name="content"
          rules={[{ required: true, message: t("pleaseEnterContent") }]}
        >
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => setContent(editor.getData())}
            config={{ extraPlugins: [MyUploadAdapterPlugin] }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("updatePost")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default EditAdminPost;
