import { useState, useEffect } from "react";
import { Card, Button, Typography, Row, Col, Table, Tag, Spin } from "antd";
import { useParams } from "react-router-dom";
import { getOrderDetail } from "../../../api/order.api";
import { Link } from "react-router-dom";
import { formattedDate } from "../../../components/common/FormattedDate";
import { formatCurrency } from "../../../components/common/FormatPrice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "./Bill.scss";
import { CloudUploadOutlined, PrinterOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const Bill = () => {
  const { t } = useTranslation("bill");
  const { code } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  const [error, setError] = useState(null);
  const { Title, Paragraph } = Typography;

  const printInvoice = () => {
    const printContent = document.getElementById("invoice").innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  };

  const saveInvoice = () => {
    const element = document.getElementById("invoice");

    html2canvas(element, {
      useCORS: true,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF();
      doc.addImage(imgData, "PNG", 10, 10, 180, 160);
      doc.save(`Hóa_đơn_${orderDetail.code}.pdf`);
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Chờ xác nhận":
        return "volcano";
      case "Đã xác nhận":
        return "green";
      case "Đang chuẩn bị hàng":
        return "cyan";
      case "Đang giao":
        return "gold";
      case "Đã giao":
        return "green";
      case "Giao thất bại":
        return "red";
      case "Hủy đơn hàng":
        return "red";
      default:
        return "default";
    }
  };

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Vui lòng đăng nhập để xem hóa đơn.");
          return;
        }

        if (!code) {
          setError("Mã đơn hàng không hợp lệ.");
          return;
        }

        const response = await getOrderDetail(code, token);
        if (response.success) {
          setOrderDetail(response.data);
        } else {
          setError("Không thể lấy thông tin hóa đơn.");
        }
      } catch (error) {
        console.error("Không thể lấy thông tin hóa đơn:", error);
        setError("Không thể lấy thông tin hóa đơn.");
      }
    };

    fetchOrderDetail();
  }, [code]);

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!orderDetail) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const columns = [
    {
      title: t("image"),
      dataIndex: "images",
      key: "images",
      render: (images) => (
        <img
          src={images[0]}
          alt={t("product")}
          style={{ width: 50, height: 60 }}
        />
      ),
      align: "center",
    },
    {
      title: t("product"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: t("quantity"),
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: t("price"),
      dataIndex: "price",
      key: "price",
      render: (price) => formatCurrency(price),
      align: "right",
    },
    {
      title: t("total"),
      dataIndex: "total",
      key: "total",
      render: (text, record) => formatCurrency(record.price * record.quantity),
      align: "right",
    },
  ];

  const productData = orderDetail.order_items.flatMap((orderItem) =>
    orderItem.items.map((item) => ({
      key: item._id,
      title: item.book.title,
      quantity: item.quantity,
      price: item.price,
      images: item.book.images,
    }))
  );

  const voucherDiscount = orderDetail.voucher;

  const subtotal = orderDetail.subtotal;
  const shippingFee = orderDetail.shipping_fee;

  const totalPrice = orderDetail.total_price;

  return (
    <>
      <Helmet>
        <title>
          {t("invoice")} #{orderDetail.code}
        </title>
      </Helmet>
      <Card className="order-detail-card">
        <Row justify="end" className="mt-4">
          <Col>
            <Button
              type="primary"
              onClick={saveInvoice}
              className="save-button"
              style={{ marginRight: "10px" }}
            >
              <CloudUploadOutlined style={{ marginRight: "5px" }} />{" "}
              {t("save_pdf")}
            </Button>

            <Button
              type="default"
              onClick={printInvoice}
              className="print-button"
            >
              <PrinterOutlined style={{ marginRight: "5px" }} />{" "}
              {t("print_invoice")}
            </Button>
          </Col>
        </Row>

        <div id="invoice">
          <Title level={2}>
            {t("invoice")} #{orderDetail.code}
          </Title>

          <Row
            gutter={16}
            className="mt-4"
            style={{ display: "flex", alignItems: "stretch" }}
          >
            <Col span={8} style={{ display: "flex" }}>
              <Card title={t("customer")} style={{ flex: 1, height: "100%" }}>
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("customer_name")}:</strong>{" "}
                  {orderDetail.buyer_info?.name || "Tên người dùng không có"}
                  <br />
                  <strong>{t("phone_number")}:</strong>{" "}
                  {orderDetail.buyer_info?.phone_number ||
                    "Số điện thoại người dùng không có"}
                  <br />
                  <strong>{t("email")}:</strong>{" "}
                  {orderDetail.buyer_info?.email || "Email người dùng không có"}
                  <br />
                  <strong>{t("address")}:</strong>{" "}
                  {orderDetail.user?.ward ? `${orderDetail.user.ward}, ` : ""}
                  {orderDetail.user?.province
                    ? `${orderDetail.user.province}, `
                    : ""}
                  {orderDetail.user?.city || t("address_not_available")}
                </Paragraph>
              </Card>
            </Col>

            <Col span={8} style={{ display: "flex" }}>
              <Card title={t("order_info")} style={{ flex: 1, height: "100%" }}>
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("total_amount")}:</strong>{" "}
                  {formatCurrency(orderDetail.total_price)}
                  <br />
                  <strong>{t("order_date")}:</strong>{" "}
                  {formattedDate(orderDetail.createdAt)}
                  <br />
                  {orderDetail.note && orderDetail.note.trim() !== "" && (
                    <>
                      <strong>{t("note")}:</strong> {orderDetail.note}
                      <br />
                    </>
                  )}
                  <strong>{t("delivery_to")}:</strong>{" "}
                  {orderDetail.buyer_info?.ward
                    ? `${orderDetail.buyer_info.ward}, `
                    : ""}
                  {orderDetail.buyer_info?.province
                    ? `${orderDetail.buyer_info.province}, `
                    : ""}
                  {orderDetail.buyer_info?.city || t("address_not_available")}
                </Paragraph>
              </Card>
            </Col>

            <Col span={8} style={{ display: "flex" }}>
              <Card
                title={t("order_status")}
                style={{ flex: 1, height: "100%" }}
              >
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("payment_method")}:</strong>{" "}
                  <Tag
                    color={
                      orderDetail.payment_method === "COD" ? "orange" : "blue"
                    }
                  >
                    {orderDetail.payment_method.toUpperCase()}
                  </Tag>
                  <br />
                  <br />
                  {orderDetail.payment_method === "VNPAY" && (
                    <>
                      <br />
                      <strong>{t("order_payment")}:</strong>{" "}
                      <Tag
                        color={
                          orderDetail.payment_status === "Chưa thanh toán"
                            ? "lightgray"
                            : "green"
                        }
                      >
                        {t(`${orderDetail.payment_status}`)}
                      </Tag>
                      <br />
                      <br />
                    </>
                  )}
                  <br />
                  <strong>{t("order_status")}:</strong>{" "}
                  <Tag color={getStatusColor(orderDetail.status)}>
                    {t(orderDetail.status)}
                  </Tag>
                </Paragraph>
              </Card>
            </Col>
          </Row>

          <Title level={3} className="mt-4">
            {t("product_info")}
          </Title>
          <Table
            columns={columns}
            dataSource={productData}
            pagination={false}
            bordered
            style={{ marginTop: "20px" }}
            summary={(pageData) => {
              let totalAmount = 0;
              pageData.forEach(({ quantity, price }) => {
                totalAmount += quantity * price;
              });

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell colSpan={4} align="right">
                    <strong>{t("subtotal")}:</strong>
                    <br />
                    <strong>{t("shipping_fee")}:</strong>
                    <br />

                    {voucherDiscount && (
                      <>
                        <strong>{t("voucher_discount")}:</strong>
                        <br />
                      </>
                    )}
                    <strong>{t("total_price")}:</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell align="right">
                    <strong>{formatCurrency(subtotal)}</strong>
                    <br />
                    <strong>+{formatCurrency(shippingFee)}</strong>
                    <br />

                    {voucherDiscount && (
                      <>
                        <strong>{voucherDiscount}</strong>
                        <br />
                      </>
                    )}

                    <strong>{formatCurrency(totalPrice)}</strong>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </div>
        <Row justify="end" className="mt-4">
          <Col>
            <Link to="/order-list">
              <Button
                type="primary"
                style={{
                  marginTop: "20px",
                  backgroundColor: "#00AB9F",
                  borderColor: "#00AB9F",
                }}
                className="Cancel-order"
              >
                {t("back_to_order_list")}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Bill;
