import { Menu } from "antd";
import {
  MdMenuBook,
  MdLibraryBooks,
  MdOutlineAddBox,
  MdOutlineReviews,
  MdOutlineCategory,
  MdOutlineLocalShipping,
  MdListAlt,
  MdPostAdd,
  MdOutlineAdminPanelSettings,
  MdDeleteOutline,
} from "react-icons/md";
import { BsBuilding, BsFileEarmarkPerson, BsFilePost } from "react-icons/bs";
import { RiCoupon2Line } from "react-icons/ri";
import { FaBoxes, FaSortNumericDown } from "react-icons/fa";
import { FaUserGroup } from "react-icons/fa6";
import { HomeOutlined, PictureOutlined, TransactionOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MenuSider() {
  const { t } = useTranslation("menuSider"); 

  const items = [
    {
      key: "sub1",
      label: <Link to="/">{t("dashboard")}</Link>,
      icon: <HomeOutlined />,
    },
    {
      key: "sub2",
      label: <Link to="/account-list">{t("accountManagement")}</Link>,
      icon: <FaUserGroup />,
    },
    {
      key: "sub3",
      label: t("bookManagement"),
      icon: <MdMenuBook />,
      children: [
        {
          key: "/book-list",
          label: <Link to="/book-list">{t("allBooks")}</Link>,
          icon: <MdLibraryBooks />
        },
        {
          key: "/add-book",
          label: <Link to="/add-book">{t("addBook")}</Link>,
          icon: <MdOutlineAddBox />
        },
        {
          key: "/trash-book",
          label: <Link to="/trash-book">{t("trashBook")}</Link>,
          icon: <MdDeleteOutline />
        },
      ],
    },
    {
      key: "sub4",
      label: <Link to="/order-list">{t("orderManagement")}</Link>,
      icon: <FaBoxes />,
    },
    {
      key: "sub5",
      label: <Link to="/tracsaction-manager">{t("transactionManagement")}</Link>,
      icon: <TransactionOutlined />,
    },
    {
      key: "sub6",
      label: <Link to="/categories">{t("categoryManagement")}</Link>,
      icon: <MdOutlineCategory />,
    },
    {
      key: "sub7",
      label: <Link to="/age-group">{t("ageGroupManagement")}</Link>,
      icon: <FaSortNumericDown />,
    },
    {
      key: "sub8",
      label: <Link to="/shipping-fee-list">{t("shippingFeeManagement")}</Link>,
      icon: <MdOutlineLocalShipping />,
    },
    {
      key: "sub10",
      label: t("postManagement"),
      icon: <BsFilePost />,
      children: [
        {
          key: "/post-list",
          label: <Link to="/post-list">{t("allPosts")}</Link>,
          icon: <MdListAlt />
        },
        {
          key: "/post-admin-list",
          label: (
            <Link to="/post-admin-list">{t("adminPosts")}</Link>
          ),
          icon: <MdOutlineReviews />,
        },
        {
          key: "/add-post",
          label: <Link to="/add-post">{t("addPost")}</Link>,
          icon: <MdPostAdd />
        }
      ],
    },
    {
      key: "sub11",
      label: <Link to="/author-manager">{t("authorManagement")}</Link>,
      icon: <BsFileEarmarkPerson />,
    },
    {
      key: "sub12",
      label: <Link to="/publisher-manager">{t("publisherManagement")}</Link>,
      icon: <BsBuilding />,
    },
    {
      key: "sub13",
      label: <Link to="/voucher-manager">{t("voucherManagement")}</Link>,
      icon: <RiCoupon2Line />,
    },
    {
      key: "sub14",
      label: <Link to="/banner-manager">{t("bannerManagement")}</Link>,
      icon: <PictureOutlined />,
    },
    {
      key: "sub15",
      label: <Link to="/admin-manager">{t("adminManagement")}</Link>,
      icon: <MdOutlineAdminPanelSettings />,
    },
  ];

  return <Menu mode="inline" items={items} defaultSelectedKeys={["/"]} defaultOpenKeys={["sub1"]} className="menu-sider"/>;
}

export default MenuSider;
