import { Navigate } from "react-router-dom";
import LayoutDefault from "../layouts/LayoutDefault";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import OrderList from "../pages/OrderManager/OrderList";
import AdminManager from "../pages/AdminManager";
import BookList from "../pages/BookManager/BookList";
import AddBook from "../pages/BookManager/AddBook";
import BookDetail from "../pages/BookManager/BookDetail";
import EditBook from "../pages/BookManager/EditBook";
import CategoryManager from "../pages/CategoriesManager";
import AccountList from "../pages/AccountManager";
import PostList from "../pages/PostManager/PostList";
import AddPost from "../pages/PostManager/AddPost";
import PostAdminList from "../pages/PostManager/AdminPostList";
import EditAdminPost from "../pages/PostManager/EditAdminPost";
import PostDetail from "../pages/PostManager/PostDetail";
import PublisherManager from "../pages/PublisherManager";
import AuthorManager from "../pages/AuthorManager";
import AgeGroupManager from "../pages/AgeGroupManager";
import ShippingManager from "../pages/ShippingManager";
import VoucherManager from "../pages/VoucherManager";
import OderDetail from "../pages/OrderManager/OrderDetail";
import PrivateRoutes from "../components/PrivateRoutes";
import Bill from "../pages/OrderManager/Bill";
import PostAdminDetail from "../pages/PostManager/PostAdminDetail";
import TrashBook from "../pages/BookManager/TrashBook";
import BannerManager from "../pages/BannerManager"
import TransactionManager from "../pages/TransactionManager";

export const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: "/",
        element: <LayoutDefault />,
        children: [
          {
            path: "/",
            element: <Navigate to="/dashboard" />,
          },
          {
            path: "dashboard",
            element: <Dashboard />,
          },
          {
            path: "order-list",
            element: <OrderList />,
          },
          {
            path: "order-detail/:code",
            element: <OderDetail />,
          },
          {
            path: "bill/:code",
            element: <Bill />,
          },
          {
            path: "admin-manager",
            element: <AdminManager />,
          },
          {
            path: "book-list",
            element: <BookList />,
          },
          {
            path: "trash-book",
            element: <TrashBook />,
          },
          {
            path: "add-book",
            element: <AddBook />,
          },
          {
            path: "book-detail/:id",
            element: <BookDetail />,
          },
          {
            path: "edit-book/:id",
            element: <EditBook />,
          },
          {
            path: "edit-admin-post/:id",
            element: <EditAdminPost />,
          },
          {
            path: "post-detail/:id",
            element: <PostDetail />,
          },
          {
            path: "categories",
            element: <CategoryManager />,
          },
          {
            path: "publisher-manager",
            element: <PublisherManager />,
          },
          {
            path: "author-manager",
            element: <AuthorManager />,
          },
          {
            path: "age-group",
            element: <AgeGroupManager />,
          },
          {
            path: "shipping-fee-list",
            element: <ShippingManager />,
          },
          {
            path: "voucher-manager",
            element: <VoucherManager />,
          },
          {
            path: "account-list",
            element: <AccountList />,
          },
          {
            path: "post-list",
            element: <PostList />,
          },
          {
            path: "add-post",
            element: <AddPost />,
          },
          {
            path: "post-admin-list",
            element: <PostAdminList />,
          },
          {
            path: "post-admin-detail/:id",
            element: <PostAdminDetail />,
          },
          {
            path: "tracsaction-manager",
            element: <TransactionManager />,
          },
          {
            path:"/banner-manager",
            element: <BannerManager />,
          },
          {
            path: "*",
            element: <NotFound />,
          },
        ],
      },
    ],
  },
];
