import { get, post, del } from "../utils/request";

export const getComments = async (postId) => {
  const result = await get(`comments?post_id=${postId}`);
  return result;
};

export const replyComment = async (data) => {
  const result = await post('comments/reply', data);
  return result;
};

export const sendComment = async (data) => {
  const result = await post('comments', data);
  return result;
};

export const delComment = async (id, token) => {
  const result = await del(`comments/${id}`, token);
  return result;
};