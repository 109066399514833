class MyUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file.then(
            (file) =>
                new Promise((resolve, reject) => {
                    const formData = new FormData();
                    formData.append("upload", file);

                    fetch(`${process.env.REACT_APP_API_URL}upload-image`, {
                        method: "POST",
                        body: formData,
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.uploaded) {
                                resolve({
                                    default: result.url, 
                                });
                            } else {
                                reject(result.error.message);
                            }
                        })
                        .catch((error) => {
                            reject(error.message);
                        });
                })
        );
    }

    abort() {
        console.log("Lỗi upload ảnh")
    }
}

function MyUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}

export { MyUploadAdapter, MyUploadAdapterPlugin };
