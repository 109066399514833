import { useEffect, useState } from "react";
import { getStatistics } from "../../api/statistics.api";
import { Col, Row, Button, Table, Tag, Input, Select, Pagination } from "antd";
import "./DashBoard.scss";
import {
  DollarCircleFilled,
  ShoppingFilled,
  AppstoreFilled,
  WalletFilled,
} from "@ant-design/icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Helmet } from "react-helmet";
import { getOrders } from "../../api/order.api";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import { formattedDate } from "../../components/common/FormattedDate";
import { formatCurrency } from "../../components/common/FormatPrice";
import { useTranslation } from "react-i18next";
function DashBoard() {
  const [orderData, setOrderData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredStatus, setFilteredStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [error, setError] = useState(null);
  const { Search } = Input;
  const [statisticsData, setStatisticsData] = useState({});
  const [selectedLine, setSelectedLine] = useState("All");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const fetchApi = async () => {
      const response = await getStatistics();
      setStatisticsData(response.data);
    };

    fetchApi();
  }, []);

  const totalRevenue = statisticsData.totalRevenue?.[0]?.totalRevenue || 0;
  const ordersCount = Array.isArray(statisticsData.ordersCount)
    ? statisticsData.ordersCount.reduce((sum, order) => sum + order.count, 0)
    : 0;
  const totalProducts =
    statisticsData.totalProductsAndCategories?.totalProducts || 0;
  const totalCategories =
    statisticsData.totalProductsAndCategories?.totalCategories || 0;
  const avgMonthlyRevenue =
    statisticsData.averageMonthlyRevenue?.[0]?.avgMonthlyRevenue || 0;

  const salesData = Array.isArray(statisticsData?.salesData)
    ? statisticsData.salesData.map((item) => {
        const monthText = t("Tháng");
        const monthNumber = item._id;

        const productsSold = Array.isArray(statisticsData?.productsSoldData)
          ? statisticsData.productsSoldData.find((p) => p._id === item._id)
          : null;

        const visitor = Array.isArray(statisticsData?.visitorsData)
          ? statisticsData.visitorsData.find((v) => v._id === item._id)
          : null;

        return {
          month: `${monthText} ${monthNumber}`,
          Sales: item.totalSales || 0,
          Orders: productsSold ? productsSold.totalProductsSold || 0 : 0,
          Visitors: visitor ? visitor.totalVisitors || 0 : 0,
        };
      })
    : [];

  const fetchOrderData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("Vui lòng đăng nhập để xem đơn hàng.");
        return;
      }
      const response = await getOrders(token);
      if (response.success && Array.isArray(response.data)) {
        setOrderData(response.data.reverse());
      }
    } catch (error) {
      console.error("Không thể lấy dữ liệu đơn hàng:", error);
      setError("Không thể lấy dữ liệu đơn hàng");
    }
  };

  useEffect(() => {
    i18n.loadNamespaces("dashboard");
  }, [i18n]);

  useEffect(() => {
    fetchOrderData();
  }, []);

  const filteredData = orderData.filter((order) => {
    const matchesSearch = order.code
      .toLowerCase()
      .includes(searchText.toLowerCase());
    const matchesStatus = filteredStatus
      ? order.status === filteredStatus
      : true;
    return matchesSearch && matchesStatus;
  });

  const columns = [
    {
      title: t("Mã đơn hàng"),
      dataIndex: "code",
      key: "code",
      align: "center",
    },
    {
      title: t("Tên khách hàng"),
      dataIndex: ["buyer_info", "name"],
      key: "buyer_name",
      align: "center",
    },
    {
      title: t("Tổng tiền"),
      dataIndex: "total_price",
      key: "total_price",
      align: "center",
      render: (text) => <span>{formatCurrency(text)}</span>,
    },
    {
      title: t("Phương thức thanh toán"),
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
      render: (text, record) => {
        const paymentMethod = text.toUpperCase();
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
            }}
          >
            <Tag color={paymentMethod === "COD" ? "orange" : "blue"}>
              {paymentMethod}
            </Tag>
            {paymentMethod === "VNPAY" && (
              <div style={{ marginTop: "4px" }}>
                <Tag
                  color={
                    record.payment_status === "Chưa thanh toán"
                      ? "lightgray"
                      : "green"
                  }
                >
                  {t(`${record.payment_status}`)}
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("Trạng thái"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => {
        let color;
        let label = status;
        switch (status) {
          case "Chờ xác nhận":
            color = "volcano";
            label = t("Chờ xác nhận"); // Dịch từ key JSON
            break;
          case "Đã xác nhận":
            color = "green";
            label = t("Đã xác nhận");
            break;
          case "Đang chuẩn bị hàng":
            color = "cyan";
            label = t("Đang chuẩn bị hàng");
            break;
          case "Đang giao":
            color = "gold";
            label = t("Đang giao");
            break;
          case "Đã giao":
            color = "green";
            label = t("Đã giao");
            break;
          case "Giao thất bại":
            color = "red";
            label = t("Giao thất bại");
            break;
          case "Hủy đơn hàng":
            color = "red";
            label = t("Hủy đơn hàng");
            break;
          default:
            color = "default";
            label = t("default");
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: t("Ngày đặt hàng"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (date) => <span>{formattedDate(date)}</span>,
    },
    {
      title: t("Hành động"),
      key: "action",
      align: "center",
      render: (text, record) => (
        <Link to={`/order-detail/${record.code}`}>
          <Button style={{ borderColor: "#00a99d", color: "#00a99d" }}>
            <AiOutlineEye /> {t("Chi tiết")}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t("sales_statistics")}</title>
      </Helmet>

      <Row gutter={[20, 20]} className="equal-height-row">
        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div className="card-box">
            <div className="icon" style={{ backgroundColor: "#E6F7F1" }}>
              <DollarCircleFilled
                style={{ fontSize: "30px", color: "#38b2ac" }}
              />
            </div>
            <div>
              <div className="title">{t("Doanh thu")}</div>
              <div className="value">{formatCurrency(totalRevenue)}</div>
              <div className="description">
                {t("Phí vận chuyển không bao gồm")}
              </div>
            </div>
          </div>
        </Col>

        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div className="card-box">
            <div className="icon" style={{ backgroundColor: "#F2FDF9" }}>
              <ShoppingFilled style={{ fontSize: "30px", color: "#38b2ac" }} />
            </div>
            <div>
              <div className="title">{t("Order")}</div>
              <div className="value">{ordersCount}</div>
              <div className="description">
                {t("Không bao gồm đơn hàng đang vận")}
              </div>
            </div>
          </div>
        </Col>

        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div className="card-box">
            <div className="icon" style={{ backgroundColor: "#FFF4E6" }}>
              <AppstoreFilled style={{ fontSize: "30px", color: "#ff9800" }} />
            </div>
            <div>
              <div className="title">{t("Sản phẩm")}</div>
              <div className="value">{totalProducts}</div>
              <div className="description">
                {t("Trong")} {totalCategories} {t("Danh mục")}
              </div>
            </div>
          </div>
        </Col>

        <Col xxl={6} xl={6} lg={6} md={12} sm={24} xs={24}>
          <div className="card-box">
            <div className="icon" style={{ backgroundColor: "#E6F7FD" }}>
              <WalletFilled style={{ fontSize: "30px", color: "#03a9f4" }} />
            </div>
            <div>
              <div className="title">{t("Thu nhập hàng tháng")}</div>
              <div className="value">{formatCurrency(avgMonthlyRevenue)}</div>
              <div className="description">
                {t("Dựa trên thời gian địa phương")}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="mt-20">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <div className="chart-box">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>{t("sales_statistics")}</h2>

              <div>
                <Button
                  onClick={() => setSelectedLine("Sales")}
                  style={{
                    margin: "0 10px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {t("Tổng doanh số")}{" "}
                  <DollarCircleFilled style={{ marginLeft: "8px" }} />
                </Button>
                <Button
                  onClick={() => setSelectedLine("Orders")}
                  style={{
                    margin: "0 10px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {t("Tổng đơn hàng")}{" "}
                  <ShoppingFilled style={{ marginLeft: "8px" }} />
                </Button>
                <Button
                  onClick={() => setSelectedLine("Visitors")}
                  style={{
                    margin: "0 10px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {t("Lượng truy cập")}{" "}
                  <AppstoreFilled style={{ marginLeft: "8px" }} />
                </Button>
                <Button
                  onClick={() => setSelectedLine("All")}
                  style={{
                    margin: "0 10px",
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                >
                  {t("Hiện tất cả")}
                </Button>
              </div>
            </div>

            <ResponsiveContainer width="100%" height="100%" className="mt-20">
              <LineChart data={salesData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />

                <YAxis yAxisId="left" />
                <YAxis yAxisId="right" orientation="right" />

                <Tooltip
                  formatter={(value, name) => {
                    const formattedValue =
                      name === "Sales" ? `${formatCurrency(value)}` : value;
                    if (name === "Sales")
                      return [formattedValue, t("Tổng doanh số")];
                    if (name === "Orders") return [value, t("Tổng đơn hàng")];
                    if (name === "Visitors")
                      return [value, t("Lượng truy cập")];
                    return [value, name];
                  }}
                />
                <Legend
                  formatter={(value) => {
                    if (value === "Sales") return t("Tổng doanh số");
                    if (value === "Orders") return t("Tổng đơn hàng");
                    if (value === "Visitors") return t("Lượng truy cập");
                    return value;
                  }}
                />
                {(selectedLine === "Sales" || selectedLine === "All") && (
                  <Line
                    yAxisId="left"
                    type="monotone"
                    dataKey="Sales"
                    stroke="#8884d8"
                    strokeWidth={2}
                  />
                )}
                {(selectedLine === "Orders" || selectedLine === "All") && (
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Orders"
                    stroke="#ffc658"
                    strokeWidth={2}
                  />
                )}
                {(selectedLine === "Visitors" || selectedLine === "All") && (
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Visitors"
                    stroke="#82ca9d"
                    strokeWidth={2}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </Col>
      </Row>

      <Row gutter={[20, 20]} className="mt-20">
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <div
            className="table-box"
            style={{
              padding: "20px",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <h3 style={{ fontWeight: "bold", fontSize: "24px" }}>
              {t("Đơn hàng mới nhất")}
            </h3>
            <div
              className="order-list-container"
              style={{ background: "#fff", padding: "0" }}
            >
              {error && <p style={{ color: "red" }}>{error}</p>}
              <Row
                gutter={8}
                className="order-list-filters"
                justify="space-between"
                align="middle"
              ></Row>
              <Table
                columns={columns}
                dataSource={filteredData.slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )}
                pagination={false}
                rowKey="_id"
                className="order-list-table"
              />
              {filteredData.length > itemsPerPage && (
                <Pagination
                  style={{
                    marginTop: "20px",
                    textAlign: "right",
                    display: "block",
                  }}
                  current={currentPage}
                  total={filteredData.length}
                  pageSize={itemsPerPage}
                  onChange={(page) => setCurrentPage(page)}
                />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default DashBoard;
