import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Row,
  Col,
  InputNumber,
  message,
  Switch,
  DatePicker,
  Image,
  Popconfirm
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { editBook, getBooksByIdOrSlug } from "../../../api/book.api";
import { getCategoriesList } from "../../../api/category.api";
import { getAuthorsList } from "../../../api/author.api";
import { getPublishersList } from "../../../api/publisher.api";
import { getAgeGroupsList } from "../../../api/ageGroup.api";
import moment from "moment";
import { Helmet } from "react-helmet";
import { MyUploadAdapterPlugin } from "../../../utils/MyUploadAdapter";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const EditBook = () => {
  const { t } = useTranslation("editBook");
  const { id: bookId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [publishers, setPublishers] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [loadingStates, setLoadingStates] = useState({
    categories: false,
    authors: false,
    publishers: false,
    ageGroups: false,
  });
  const [parentCategories, setParentCategories] = useState([]);
  const [childCategories, setChildCategories] = useState([]);
  const [showChildSelect, setShowChildSelect] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  const [removeImages, setRemoveImages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          categoryResponse,
          authorResponse,
          publisherResponse,
          ageGroupResponse,
          bookResponse,
        ] = await Promise.all([
          getCategoriesList(),
          getAuthorsList(),
          getPublishersList(),
          getAgeGroupsList(),
          getBooksByIdOrSlug(bookId),
        ]);

        if (categoryResponse.success && categoryResponse.data.length > 0) {
          const categoriesData = categoryResponse.data;
          setCategories(categoriesData);
          setParentCategories(categoriesData);
        }

        if (authorResponse.success) {
          setAuthors(authorResponse.data);
        }
        if (publisherResponse.success) {
          setPublishers(publisherResponse.data);
        }
        if (ageGroupResponse.success) {
          setAgeGroups(ageGroupResponse.data);
        }

        if (bookResponse.success) {
          const bookData = bookResponse.data;
          setDescription(bookData.description);

          if (bookData.category) {
            const parentCategory = bookData.category.parent;
            const childCategory = bookData.category;

            if (parentCategory) {
              const parent = categoryResponse.data.find(
                (cat) => cat._id === parentCategory._id
              );
              if (parent) {
                setChildCategories(parent.children || []);
                setShowChildSelect(true);
                form.setFieldsValue({
                  parent_category: parent._id,
                  child_category: childCategory._id,
                });
              }
            } else {
              form.setFieldsValue({
                parent_category: childCategory._id,
              });
            }
          }

          form.setFieldsValue({
            title: bookData.title,
            price: bookData.price,
            discount_price: bookData.discount_price,
            stock: bookData.stock,
            published_at: moment(bookData.detail.published_at),
            format: bookData.detail.format,
            pages: bookData.detail.pages,
            length: bookData.detail.dimensions.length,
            width: bookData.detail.dimensions.width,
            height: bookData.detail.dimensions.height,
            weight: bookData.detail.weight,
            is_hidden: bookData.is_hidden,
            author: bookData.author ? bookData.author._id : null,
            publisher: bookData.publisher ? bookData.publisher._id : null,
            age_group: bookData.age_group ? bookData.age_group._id : null,
          });

          setCurrentImages(bookData.images || []);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error;
        message.error(errorMessage);
      } finally {
        setLoadingStates((prevState) => ({
          ...prevState,
          categories: false,
        }));
      }
    };
    fetchData();
  }, [bookId]);

  const handleParentChange = (parentId) => {
    const selectedParentCategory = categories.find(
      (cat) => cat._id === parentId
    );
    setChildCategories(selectedParentCategory?.children || []);
    setShowChildSelect(!!selectedParentCategory?.children?.length);
    form.setFieldsValue({ child_category: null });
  };

  const onFinish = async (values) => {
    setLoading(true);

    if (currentImages.length === 0 && selectedFiles.length === 0) {
      message.error(t("errorUploadImage"));
      setLoading(false);
      return;
    }

    const formData = new FormData();

    const categoryId = values.child_category || values.parent_category;
    formData.append("category", categoryId);

    Object.entries(values).forEach(([key, value]) => {
      if (key !== "parent_category" && key !== "child_category" && value !== undefined) {
        if (key === "published_at") {
          formData.append("detail[published_at]", value.format("YYYY-MM-DD"));
        } else {
          formData.append(key, value);
        }
      }
    });

    selectedFiles.forEach((file) => {
      formData.append("newImages", file);
    });

    removeImages.forEach((image) => {
      formData.append("removeImages", image);
    });

    try {
      await editBook(bookId, formData);
      message.success(t("success_message"));
      navigate("/book-list");
    } catch (error) {
      const errorMessage = error.response?.data?.error || "Error updating book";
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (imageUrl) => {
    setRemoveImages((prev) => [...prev, imageUrl]);
    setCurrentImages((prev) => prev.filter((img) => img !== imageUrl));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
    form.setFieldsValue({ description: data });
  };

  const props = {
    name: "file",
    multiple: true,
    fileList: selectedFiles,
    beforeUpload: (file) => {
      const isImage = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
      if (!isImage) {
        message.error("Invalid image format");
      }
      return isImage;
    },
    onChange: (info) => {
      const { fileList } = info;
      const validFiles = fileList.filter(file => file.status !== 'uploading');
      setSelectedFiles(validFiles);
    },
    onRemove: (file) => {
      setSelectedFiles(prev => prev.filter(item => item.uid !== file.uid));
    },
    customRequest: ({ file, onSuccess, onError }) => {
      setSelectedFiles(prevFiles => [...prevFiles, file]);
      onSuccess();
    },
  };

  return (
    <>
      <Helmet>
        <title>{t("edit_book_title")}</title>
      </Helmet>

      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "20px" }}
      >
        <h2 style={{ fontSize: "24px", fontWeight: "bold", color: "#334155" }}>
          {t("edit_book_title")}
        </h2>

        <Button className="btn-add-book" onClick={() => form.submit()} loading={loading}>
          {t("update_book_button")}
        </Button>
      </Row>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={15}>
            <div className="add-book-section">
              <h3 className="add-book-title">{t("book_info_section")}</h3>
              <Form.Item
                name="title"
                label={t("book_title_label")}
                rules={[{ required: true, message: t("rules.required_title") }]}
              >
                <Input placeholder="Type here" />
              </Form.Item>

              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item
                    name="price"
                    label={t("price_label")}
                    rules={[
                      { required: true, message: t("rules.required_price") },
                    ]}
                  >
                    <InputNumber
                      placeholder="VNĐ"
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        value && `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ`
                      }
                      parser={(value) =>
                        value?.replace(/\s?VNĐ/g, "").replace(/\./g, "")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="discount_price"
                    label={t("discount_price_label")}
                  >
                    <InputNumber
                      placeholder="VNĐ"
                      style={{ width: "100%" }}
                      formatter={(value) =>
                        value && `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} VNĐ`
                      }
                      parser={(value) =>
                        value?.replace(/\s?VNĐ/g, "").replace(/\./g, "")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="stock"
                    label={t("stock_label")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_stock"),
                      },
                    ]}
                  >
                    <InputNumber min={0} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                label={t("description_label")}
                name="description"
                rules={[
                  { required: true, message: t("description_placeholder") },
                ]}
              >
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleEditorChange}
                  config={{ extraPlugins: [MyUploadAdapterPlugin] }}
                />
              </Form.Item>
            </div>

            <div className="add-book-section">
              <h3 className="add-book-title">{t("detail_info_section")}</h3>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="published_at"
                    label={t("published_date_label")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_published_date"),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder={t("published_date_placeholder")}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="format" label={t("format_label")}>
                    <Input
                      placeholder={t("format_placeholder")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="pages"
                    label={t("pages_label")}
                    rules={[
                      { required: true, message: t("rules.required_pages") },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("pages_label")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="weight" label={t("weight_label")}>
                    <InputNumber
                      placeholder={t("weight_label")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={6}>
                  <Form.Item
                    name="length"
                    label={t("dimensions_length_label")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_length"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_length_label")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="width"
                    label={t("dimensions_width_label")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_width"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_width_label")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="height"
                    label={t("dimensions_height_label")}
                    rules={[
                      {
                        required: true,
                        message: t("rules.required_dimensions_height"),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("dimensions_height_label")}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="is_hidden" label={t("is_hidden_label")}>
                    <Switch
                      checkedChildren={t("switch_hidden_checked")}
                      unCheckedChildren={t("switch_hidden_unchecked")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={9}>
            <div className="add-book-section">
              <h3 className="add-book-title">{t("upload_image_section")}</h3>
              <Form.Item name="images">
                <Upload.Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">{t("upload_image_text")}</p>
                </Upload.Dragger>
              </Form.Item>
            </div>

            <div className="add-book-section">
              <h3>{t("current_images_section")}</h3>
              <Row gutter={16}>
                <Image.PreviewGroup>
                  {currentImages.map((src, index) => (
                    <div key={index} style={{ position: "relative", display: "inline-block" }}>
                      <Image
                        src={src}
                        alt={`Image ${index + 1}`}
                        style={{
                          width: "120px",
                          height: "120px",
                          objectFit: "cover",
                          margin: "2px",
                        }}
                      />
                      <Popconfirm
                        title={t("confirmDelete")} 
                        onConfirm={() => handleRemoveImage(src)}
                        okText={t("yes")} 
                        cancelText={t("no")} 
                      >
                        <Button
                          type="link"
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                            background: "rgba(0, 0, 0, 0.5)",
                            color: "white",
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Popconfirm>
                    </div>
                  ))}
                </Image.PreviewGroup>
              </Row>
            </div>

            <div className="add-book-section">
              <Form.Item
                name="author"
                label={t("author_label")}
                rules={[
                  { required: true, message: t("rules.required_author") },
                ]}
              >
                <Select
                  placeholder={t("author_placeholder")}
                  loading={loadingStates.authors}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {authors.map((author) => (
                    <Option key={author._id} value={author._id}>
                      {author.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="publisher"
                label={t("publisher_label")}
                rules={[
                  { required: true, message: t("rules.required_publisher") },
                ]}
              >
                <Select
                  placeholder={t("publisher_placeholder")}
                  loading={loadingStates.publishers}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {publishers.map((publisher) => (
                    <Option key={publisher._id} value={publisher._id}>
                      {publisher.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="age_group"
                label={t("age_group_label")}
                rules={[
                  { required: true, message: t("rules.required_age_group") },
                ]}
              >
                <Select
                  placeholder={t("age_group_placeholder")}
                  loading={loadingStates.ageGroups}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {ageGroups.map((group) => (
                    <Option key={group._id} value={group._id}>
                      {group.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className="add-book-section">
              <h3 className="add-book-title">{t("category")}</h3>
              <Form.Item
                name="parent_category"
                label={t("parent_category_label")}
                rules={[
                  {
                    required: true,
                    message: t("rules.required_parent_category"),
                  },
                ]}
              >
                <Select
                  placeholder={t("parent_category_placeholder")}
                  loading={loadingStates.categories}
                  showSearch
                  onChange={handleParentChange}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {parentCategories.map((category) => (
                    <Option key={category._id} value={category._id}>
                      {category.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {showChildSelect && (
                <Form.Item
                  name="child_category"
                  label={t("child_category_label")}
                >
                  <Select
                    placeholder={t("child_category_placeholder")}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {childCategories.map((category) => (
                      <Option key={category._id} value={category._id}>
                        {category.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditBook;
