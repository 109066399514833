import { del, get, putMulti, patch, postMulti } from "../utils/request";


export const getBooksList = async (showHidden = false) => {
    const result = await get(`books?showHidden=${showHidden}&limit=1000&page=1`);
    return result;
  };

export const getBooksByIdOrSlug = async (idOrSlug) => {
    const result = await get(`books/${idOrSlug}`);
    return result;
};

export const createBook = async (options) => {
    const result = await postMulti(`books`, options);
    return result;
};

export const deleteBook = async (id) => {
    const result = await del(`books/${id}`);
    return result;
};

export const editBook = async (id, options) => {
    const result = await putMulti(`books/${id}`, options);
    return result;
};

export const toggleHiddenStatus = async (id, isHidden) => {
    const result = await patch(`books/${id}/is-hidden`, { is_hidden: isHidden });
    return result;
};

export const deleteStatus = async (id) => {
    const result = await patch(`books/toggle-delete-status/${id}`);
    return result;
};

export const getTemporaryDelete = async () => {
    const result = await get('books?isDeleted=true&limit=1000&page=1');
    return result;
};