import { get, post, put, del } from "../utils/request";

export const getCategoriesList = async () => {
  const result = await get("categories");
  return result;
};

export const addCategory = async (data) => {
  const result = await post("categories", data);  
  return result;
};

export const editCategory = async (id, data) => {
  const result = await put(`categories/${id}`, data);  
  return result;
};

export const deleteCategory = async (id) => {
  const result = await del(`categories/${id}`); 
  return result;
};
