import { useState, useEffect } from "react";
import {
  Card,
  Steps,
  Button,
  Typography,
  Row,
  Select,
  Col,
  Space,
  message,
  Spin,
  Flex,
  Tag,
} from "antd";
import "./Order-detail.scss";
import { useParams } from "react-router-dom";
import { getOrderDetail, updateOrderStatus } from "../../../api/order.api";
import {
  CalendarOutlined,
  ShoppingCartOutlined,
  SettingOutlined,
  CheckCircleOutlined,
  TruckOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { formattedDate } from "../../../components/common/FormattedDate";
import { formatCurrency } from "../../../components/common/FormatPrice";
import { formattedTime } from "../../../components/common/FormatTime";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const OrderDetail = () => {
  const { code } = useParams();
  const { t } = useTranslation("orderDetail");

  const [orderDetail, setOrderDetail] = useState({
    _id: "",
    status: "",
  });

  const [error, setError] = useState(null);
  const { Title, Paragraph } = Typography;
  const { Option } = Select;
  const initialOrderStatus = [
    {
      icon: <ShoppingCartOutlined />,
      title: "Chờ xác nhận",
      status: "finished",
    },
    {
      icon: <SettingOutlined />,
      title: "Đã xác nhận",
      status: "in-progress",
    },
    {
      icon: <CheckCircleOutlined />,
      title: "Đang chuẩn bị hàng",
      status: "waiting",
    },
    {
      icon: <TruckOutlined />,
      title: "Đang giao",
      status: "waiting",
    },
    {
      icon: <DownloadOutlined />,
      title: "Đã giao",
      status: "waiting",
    },
  ];

  const [orderStatus, setOrderStatus] = useState(initialOrderStatus);

  const [isCanceled, setIsCanceled] = useState(false);

  const autoUpdateOrderStatus = (currentStatus) => {
    const updatedStatus = initialOrderStatus.map((step, index) => {
      if (step.title === currentStatus) {
        return { ...step, status: "in-progress" };
      } else if (
        index <
        initialOrderStatus.findIndex((step) => step.title === currentStatus)
      ) {
        return { ...step, status: "finished" };
      } else {
        return { ...step, status: "waiting" };
      }
    });
    setOrderStatus(updatedStatus);
  };

  useEffect(() => {
    if (orderDetail.status && !isCanceled) {
      autoUpdateOrderStatus(orderDetail.status);
    }
  }, [orderDetail.status, isCanceled]);

  const handleStatusChange = async (value, id) => {
    if (value === "Giao thất bại") {
      setIsCanceled(true);
      const updatedStatus = orderStatus.map((step) => ({
        ...step,
        status: step.title === "Giao thất bại" ? "finished" : "waiting",
      }));
      setOrderStatus(updatedStatus);

      try {
        await updateOrderStatus(id, value);
        setOrderDetail((prevState) => ({
          ...prevState,
          status: value,
        }));
        message.success("Cập nhật trạng thái đơn hàng thành công!");
      } catch (error) {
        message.error("Có lỗi xảy ra khi cập nhật trạng thái!");
      }

      return;
    }
    setIsCanceled(false);
    const selectedIndex = orderStatus.findIndex((step) => step.title === value);

    const updatedStatus = orderStatus.map((step, index) => {
      if (index < selectedIndex) {
        return { ...step, status: "finished" };
      } else if (index === selectedIndex) {
        return { ...step, status: "in-progress" };
      } else {
        return { ...step, status: "waiting" };
      }
    });

    setOrderStatus(updatedStatus);

    try {
      await updateOrderStatus(id, value);
      setOrderDetail((prevState) => ({
        ...prevState,
        status: value,
      }));
      message.success("Cập nhật trạng thái đơn hàng thành công!");
    } catch (error) {
      message.error("Có lỗi xảy ra khi cập nhật trạng thái!");
    }
  };

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("Vui lòng đăng nhập để xem chi tiết đơn hàng.");
          return;
        }

        if (!code) {
          setError("Mã đơn hàng không hợp lệ.");
          return;
        }

        const response = await getOrderDetail(code, token);

        if (response.success) {
          setOrderDetail(response.data);
        } else {
          setError("Không thể lấy thông tin chi tiết đơn hàng.");
        }
      } catch (error) {
        console.error("Không thể lấy thông tin chi tiết đơn hàng:", error);
        setError("Không thể lấy thông tin chi tiết đơn hàng.");
      }
    };

    fetchOrderDetail();
  }, [code]);

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!orderDetail) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (
    !Array.isArray(orderDetail.order_items) ||
    orderDetail.order_items.length === 0
  ) {
    return (
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  const getStatusColor = (status) => {
    switch (status) {
      case "Chờ xác nhận":
        return "volcano";
      case "Đã xác nhận":
        return "green";
      case "Đang chuẩn bị hàng":
        return "cyan";
      case "Đang giao":
        return "gold";
      case "Đã giao":
        return "green";
      case "Giao thất bại":
        return "red";
      case "Hủy đơn hàng":
        return "red";
      default:
        return "default";
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t("order_detail")} #{orderDetail.code}
        </title>
      </Helmet>
      <div className="container mt-5">
        <Card className="order-tracking">
          <Title level={3}>
            {t("order_detail")} <strong>#{orderDetail.code}</strong>
          </Title>
          <Paragraph className="order-details">
            {t("order_time")}: {formattedDate(orderDetail.createdAt)}
          </Paragraph>
          <Row justify="space-between" align="middle" className="mt-3">
            <Col>
              <Space align="center">
                <CalendarOutlined />
                <span> {t(`${orderDetail.status}`)}</span>
              </Space>
            </Col>

            {orderDetail.status !== "Hủy đơn hàng" && (
              <Col>
                <Select
                  className="mr-2"
                  value={orderDetail.status}
                  placeholder="Chọn trạng thái đơn hàng"
                  onChange={(value) =>
                    handleStatusChange(value, orderDetail._id)
                  }
                  style={{ width: 180 }}
                  popupMatchSelectWidth={false}
                  allowClear
                >
                  <Option
                    value="Chờ xác nhận"
                    disabled={[
                      "Đã xác nhận",
                      "Đang chuẩn bị hàng",
                      "Đang giao",
                      "Đã giao",
                      "Giao thất bại",
                    ].includes(orderDetail.status)}
                  >
                    {t("waiting_confirmation")}
                  </Option>
                  <Option
                    value="Đã xác nhận"
                    disabled={[
                      "Đang chuẩn bị hàng",
                      "Đang giao",
                      "Đã giao",
                      "Giao thất bại",
                    ].includes(orderDetail.status)}
                  >
                    {t("confirmed")}
                  </Option>
                  <Option
                    value="Đang chuẩn bị hàng"
                    disabled={[
                      "Đang giao",
                      "Đã giao",
                      "Giao thất bại",
                    ].includes(orderDetail.status)}
                  >
                    {t("preparing")}
                  </Option>
                  <Option
                    value="Đang giao"
                    disabled={["Đã giao", "Giao thất bại"].includes(
                      orderDetail.status
                    )}
                  >
                    {t("shipping")}
                  </Option>
                  <Option
                    value="Đã giao"
                    disabled={orderDetail.status === "Giao thất bại"}
                  >
                    {t("delivered")}
                  </Option>
                  <Option
                    value="Giao thất bại"
                    disabled={orderDetail.status === "Đã giao"}
                  >
                    {t("delivery_failed")}
                  </Option>
                </Select>
              </Col>
            )}
          </Row>

          {orderDetail.status === "Hủy đơn hàng" ? (
            <Row justify="center" className="mt-4">
              <Col>
                <Paragraph type="danger" className="cancel-message">
                  <CloseCircleOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />
                  <span style={{ fontSize: "20px" }}>{t("Đơn hàng đã bị hủy")}</span>
                </Paragraph>
              </Col>
            </Row>
          ) : (
            <Steps className="mt-4 mb-3 steps-margin" direction="horizontal">
              {orderStatus.map((step, index) => (
                <Steps.Step
                  key={index}
                  icon={
                    <div className={`step-icon step-icon-${step.status}`}>
                      {step.icon}
                    </div>
                  }
                  title={
                    <div className={`step-title step-title-${step.status}`}>
                      {t(`${step.title}`)}
                    </div>
                  }
                  description={
                    <div className={`step-date step-date-${step.status}`}>
                      {step.date}
                    </div>
                  }
                  className={`step-${step.status}`}
                />
              ))}
            </Steps>
          )}

          <Row
            gutter={16}
            className="mt-4"
            style={{ display: "flex", alignItems: "stretch" }}
          >
            <Col span={8} style={{ display: "flex" }}>
              <Card title={t("customer")} style={{ flex: 1, height: "100%" }}>
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("customer_name")}:</strong>{" "}
                  {orderDetail.buyer_info?.name || "Tên người dùng không có"}
                  <br />
                  <strong>{t("phone_number")}:</strong>{" "}
                  {orderDetail.buyer_info?.phone_number ||
                    "Số điện thoại người dùng không có"}
                  <br />
                  <strong>{t("email")}:</strong>{" "}
                  {orderDetail.buyer_info?.email || "Email người dùng không có"}
                  <br />
                  <strong>{t("address")}:</strong>{" "}
                  {orderDetail.user?.ward ? `${orderDetail.user.ward}, ` : ""}
                  {orderDetail.user?.province
                    ? `${orderDetail.user.province}, `
                    : ""}
                  {orderDetail.user?.city || t("address_not_available")}
                </Paragraph>
              </Card>
            </Col>

            <Col span={8} style={{ display: "flex" }}>
              <Card title={t("order_info")} style={{ flex: 1, height: "100%" }}>
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("total_amount")}:</strong>{" "}
                  {formatCurrency(orderDetail.total_price)}
                  <br />
                  <strong>{t("order_date")}:</strong>{" "}
                  {formattedDate(orderDetail.createdAt)}
                  <br />
                  {orderDetail.note && orderDetail.note.trim() !== "" && (
                    <>
                      <strong>{t("note")}:</strong>
                      <span
                        style={{
                          marginLeft: "3px",
                          color: orderDetail.status === "Hủy đơn hàng" ? "red" : "inherit",
                          fontWeight: orderDetail.status === "Hủy đơn hàng" ? "bold" : "normal"
                        }}
                      >
                        {orderDetail.note}
                      </span>
                      <br />
                    </>
                  )}
                  <strong>{t("delivery_to")}:</strong>{" "}
                  {orderDetail.buyer_info?.ward
                    ? `${orderDetail.buyer_info.ward}, `
                    : ""}
                  {orderDetail.buyer_info?.province
                    ? `${orderDetail.buyer_info.province}, `
                    : ""}
                  {orderDetail.buyer_info?.city || t("address_not_available")}
                </Paragraph>
              </Card>
            </Col>

            <Col span={8} style={{ display: "flex" }}>
              <Card
                title={t("order_status")}
                style={{ flex: 1, height: "100%" }}
              >
                <Paragraph style={{ lineHeight: "2" }}>
                  <strong>{t("payment_method")}:</strong>{" "}
                  <Tag
                    color={
                      orderDetail.payment_method === "COD" ? "orange" : "blue"
                    }
                  >
                    {orderDetail.payment_method.toUpperCase()}
                  </Tag>
                  <br />
                  <br />
                  {orderDetail.payment_method === "VNPAY" && (
                    <>
                      <br />
                      <strong>{t("order_payment")}:</strong>{" "}
                      <Tag
                        color={
                          orderDetail.payment_status === "Chưa thanh toán"
                            ? "lightgray"
                            : "green"
                        }
                      >
                        {t(`${orderDetail.payment_status}`)}
                      </Tag>
                      <br />
                      <br />
                    </>
                  )}
                  <br />
                  <strong>{t("order_status")}:</strong>{" "}
                  <Tag color={getStatusColor(orderDetail.status)}>
                    {t(orderDetail.status)}
                  </Tag>
                </Paragraph>
              </Card>
            </Col>
          </Row>

          <Col>
            <Link to={`/bill/${orderDetail.code}`}>
              <Button type="primary" block className="mt-4 primary">
                {t("view_bill")}
              </Button>
            </Link>
          </Col>
        </Card>
      </div>
    </>
  );
};

export default OrderDetail;
